import React from "react";
import {Container, Spinner} from "react-bootstrap";
import Footer from "./Footer";
import NavBarTop from "./NavBarTop";

interface IProps {
	id?: string;
	fluid?: boolean;
	isLoading?: boolean;
	containerClass?: string;
}

export default class DefaultLayout extends React.Component<IProps> {
	public render() {
		const layoutClass = 'colored d-flex flex-column min-vh-100';
		const isFluid = this.props?.fluid === true;
		const containerClass = 'pb-5' + (this.props.containerClass ? ` ${this.props.containerClass}` : '');

		return (
			<div id={this.props.id} className={layoutClass}>
				<NavBarTop />

				<main className="flex-fill">
					<Container fluid={isFluid} className={containerClass}>
						{this.props.isLoading ?
							<div className="text-center">
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>

								<div className="mt-2">Načítám...</div>
							</div> :
							 this.props.children
						}
					</Container>
				</main>

				<Footer />
			</div>
		)
	}
}
