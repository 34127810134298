import React from "react";

import "../commons/styles/forms.scss";
import DefaultManageLayout from "./DefaultManageLayout";
import {AgGridReact} from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";
import {IpsosEmployeeResponse, IpsosIntroductionQuestionnaireResponse} from "../api/client";
import _ from "lodash";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

interface IState {
	count: number;
	users?: IpsosEmployeeResponse[];
	data?: IpsosIntroductionQuestionnaireResponse[];
	columnDefs: any;
	defaultColDef: any;
}

export default class ManageAfterRegistration extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);

		this.state = {
			count: 0,
			users: [],
			data: [],
			columnDefs: [
				{
					field: "id",
					headerName: "ID",
					width: 100
				},
				{
					field: "personId",
					headerName: "Zaměstnanec",
					valueFormatter: this.formatEmployee.bind(this)
				},
				{
					field: "createdOn",
					headerName: "Vyplněno",
					valueFormatter: this.formatDate.bind(this)
				},
				{
					field: "customActivity",
					headerName: "Způsob zapojení",
					width: 150,
					valueFormatter: this.formatCustomActivity
				},
				{
					headerName: "Vlastní aktivita",
					children: [
						{
							field: "customOrganization",
							headerName: "Organizace"
						},
					]
				},
				{
					headerName: "Máš Chvilku",
					children: [
						{
							headerName: "Největší zájem",
							valueGetter: this.getPreferredActivities
						},
						{
							headerName: "Nezájem",
							valueGetter: this.getExcludedActivities
						},
						{
							field: "individual",
							headerName: "Sám?",
							width: 100,
							valueFormatter: this.formatIndividual
						},
						{
							field: "accompanion",
							headerName: "Tým",
							valueFormatter: this.formatAccompanion
						},
						{
							field: "preferedWeek",
							headerName: "Termín",
							width: 150,
							valueFormatter: this.formatWeek
						},
					]
				},
				{
					field: "benefitOpinionRank",
					headerName: "Hodnocení benefitu",
					valueFormatter: this.formatScale
				},
			],
			defaultColDef: {
				sortable: true,
				resizable: true,
				width: 200
			}
		};
	}

	async componentDidMount() {
		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const countResponse = await client.ipsos.countIntroductionQuestionnaires();
		const count = countResponse.data?.count ?? 0;
		this.setState({ count });

		const usersResponse = await client.ipsos.getUsers();
		const users = usersResponse.data;
		this.setState({ users });

		const response = await client.ipsos.getIntroductionQuestionnaires();
		const data = _.sortBy(response.data, d => d.createdOn).reverse();
		this.setState({ data });
	}

	formatEmployee(params: any) {
		const employee = this.state?.users?.find(u => u.id === params.value);
		return `${employee?.lastName} ${employee?.firstName} (${employee?.email})`;
	}

	formatCustomActivity(params: any) {
		return params.value === true ? 'Vlastní' : 'Máš Chvilku';
	}


	formatDate(params: any) {
		const localTime = utcToZonedTime(params.data.createdOn, "Europe/Prague");

		return format(localTime, "d. M. y H:mm");
	}

	getPreferredActivities(params: any) {
		let result = "";
		if (params.data.preferNature === true) result += "Příroda, ";
		if (params.data.preferAnimals === true) result += "Zvířata, ";
		if (params.data.preferChildren === true) result += "Děti, ";
		if (params.data.preferElder === true) result += "Senioři, ";
		if (params.data.preferEmergency === true) result += "Krizové situace, ";

		return result.slice(0, -2);
	}

	getExcludedActivities(params: any) {
		let result = "";
		if (params.data.excludeNature === true) result += "Příroda, ";
		if (params.data.excludeAnimals === true) result += "Zvířata, ";
		if (params.data.excludeChildren === true) result += "Děti, ";
		if (params.data.excludeElder === true) result += "Senioři, ";
		if (params.data.excludeEmergency === true) result += "Krizové situace, ";

		return result.slice(0, -2);
	}

	formatScale(params: any) {
		switch (params.value) {
			case 0: return 'Velice negativně';
			case 1: return 'Spíše negativně';
			case 2: return 'Neutrálně';
			case 3: return 'Spíše pozitivně';
			case 4: return 'Velice pozitivně';
			default: return 'N/A';
		}
	}

	formatIndividual(params: any) {
		if (params.value === true) {
			return "Ano";
		} else if (params.value === false) {
			return "Ne";
		} else {
			return "";
		}
	}

	formatAccompanion(params: any) {
		if (params.data.individual === true) {
			return "---";
		}

		return params.data.accompanion;
	}

	formatWeek(params: any) {
		switch (params.data.preferedWeek) {
			case 0: return "25.4. - 1.5.2022";
			case 1: return "2.5. - 8.5.2022";
			case 2: return "9.5. - 15.5.2022";
			case 3: return "16.5. - 22.5.2022";
			case 4: return "23.5. - 29.5.2022";
			case 5: return "30.5. - 5.6.2022";
			case 6: return "6.6. - 12.6.2022";
			case 7: return "13.6. - 19.6.2022";
			case 8: return "20.6. - 26.6.2022";
			case 9: return "27.6. - 3.7.2022";
			case 10: return "4.7. - 10.7.2022";
			case 11: return "11.7. - 17.7.2022";
			case 12: return "18.7. - 24.7.2022";
			case 13: return "25.7. - 31.7.2022";
			case 14: return "1.8. - 7.8.2022";
			case 15: return "8.8. - 14.8.2022";
			case 16: return "15.8. - 21.8.2022";
			case 17: return "22.8. - 28.8.2022";
			case 18: return "Nevím, potřebuji řešit individuálně.";
			default: return "";
		}
	}

	render() {
		return (
			<>
				<DefaultManageLayout>
					<div className="ag-theme-balham" style={{height: 600, width: '100%'}}>
						<AgGridReact
							defaultColDef={this.state.defaultColDef}
							columnDefs={this.state.columnDefs}
							rowData={this.state.data}>
						</AgGridReact>
					</div>

					<div className="mt-5">
						Vyplněno dotazníků: {this.state.count}
					</div>
				</DefaultManageLayout>
			</>
		)
	}
}
