import React from "react";

import "../commons/styles/forms.scss";
import DefaultManageLayout from "./DefaultManageLayout";
import {AgGridReact} from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";
import {IpsosEmployeeResponse, IpsosImageResponse, IpsosIntroductionQuestionnaireResponse} from "../api/client";
import _ from "lodash";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {Alert, Button, Col, Row} from "react-bootstrap";


interface IState {
	count: number;
	users?: IpsosEmployeeResponse[];
	data?: IpsosIntroductionQuestionnaireResponse[];
	columnDefs: any;
	defaultColDef: any;
	selectedRow?: any;
	selectedRowId?: number;
	files: IpsosImageResponse[];
}

export default class ManageAfterActivity extends React.Component<{}, IState> {
	static contextType = AppContext;

	private gridApi: any;

	constructor(props: {}) {
		super(props);

		this.state = {
			count: 0,
			users: [],
			data: [],
			columnDefs: [
				{
					field: "id",
					headerName: "ID",
					width: 100
				},
				{
					field: "personId",
					headerName: "Zaměstnanec",
					valueFormatter: this.formatEmployee.bind(this)
				},
				{
					field: "createdOn",
					headerName: "Vyplněno",
					valueFormatter: this.formatDate.bind(this)
				},
				{
					field: "impression",
					headerName: "Pocity"
				},
				{
					field: "activity",
					headerName: "Aktivita"
				},
				{
					field: "place",
					headerName: "Lokalita"
				},
				{
					field: "satisfaction",
					headerName: "Celková spokojenost"
				},
				{
					field: "feedback",
					headerName: "Náměty ke zlepšení"
				},
				{
					field: "hasFellowParticipants",
					headerName: "Spoluúčastníci?",
					valueFormatter: this.formatOptionalBool
				},
				{
					field: "fellowParticipants",
					headerName: "Spoluúčastníci",
					valueGetter: this.getFellowParticipants
				},
				{
					field: "willRepeat",
					headerName: "Zájem - firemní aktivity",
					valueFormatter: this.formatScale
				},
				{
					field: "willRepeatInFreeTime",
					headerName: "Zájem - volný čas",
					valueFormatter: this.formatScale
				}
			],
			defaultColDef: {
				sortable: true,
				resizable: true,
				width: 200
			},
			files: []
		};
	}

	async componentDidMount() {
		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const countResponse = await client.ipsos.countActivityQuestionnaires();
		const count = countResponse.data?.count ?? 0;
		this.setState({ count });

		const usersResponse = await client.ipsos.getUsers();
		const users = usersResponse.data;
		this.setState({ users });

		const response = await client.ipsos.getActivityQuestionnaires();
		const data = _.sortBy(response.data, d => d.createdOn).reverse();
		this.setState({ data });
	}

	formatOptionalBool(params: any) {
		if (params.value === true) {
			return "Ano";
		} else if (params.value === false) {
			return "Ne";
		} else {
			return "";
		}
	}

	getFellowParticipants(params: any) {
		if (params.data.hasFellowParticipants !== true) {
			return "---";
		}

		return params.data.fellowParticipants;
	}

	formatEmployee(params: any) {
		const employee = this.state?.users?.find(u => u.id === params.value);
		return `${employee?.lastName} ${employee?.firstName} (${employee?.email})`;
	}

	formatScale(params: any) {
		switch (params.value) {
			case 0: return "Nevím";
			case 1: return "Určitě ne";
			case 2: return "Spíše ne";
			case 3: return "Spíše ano";
			case 4: return "Určitě ano";
			default: return "";
		}
	}

	formatAccompanion(params: any) {
		if (params.data.individual === true) {
			return "---";
		}

		return params.data.accompanion;
	}

	formatWeek(params: any) {
		switch (params.data.preferedWeek) {
			case 0: return "25.4. - 1.5.2022";
			case 1: return "2.5. - 8.5.2022";
			case 2: return "9.5. - 15.5.2022";
			case 3: return "16.5. - 22.5.2022";
			case 4: return "23.5. - 29.5.2022";
			case 5: return "30.5. - 5.6.2022";
			case 6: return "6.6. - 12.6.2022";
			case 7: return "13.6. - 19.6.2022";
			case 8: return "20.6. - 26.6.2022";
			case 9: return "27.6. - 3.7.2022";
			case 10: return "4.7. - 10.7.2022";
			case 11: return "11.7. - 17.7.2022";
			case 12: return "18.7. - 24.7.2022";
			case 13: return "25.7. - 31.7.2022";
			default: return "";
		}
	}

	formatDate(params: any) {
		const localTime = utcToZonedTime(params.data.createdOn, "Europe/Prague");

		return format(localTime, "d. M. y H:mm");
	}

	onGridReady(params: any) {
		this.gridApi = params.api;
	};

	async onRowSelection() {
		const selectedRows = this.gridApi.getSelectedRows();
		const selectedRowId = selectedRows.length === 1 ? selectedRows[0].id : undefined;

		this.setState({
			selectedRowId,
			selectedRow: selectedRows[0]
		});
		await this.retrieveFiles(selectedRowId);
	}

	async retrieveFiles(id?: number) {
		if (!id) {
			return;
		}

		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const filesResponse = await client.ipsos.getActivityImages(id);
		const files = filesResponse.data;

		this.setState({ files });
	}

	async download(id?: number, fileName?: string) {
		if (!id || !fileName) {
			return;
		}

		const client = await MasChvilkuApiClient.getInstance(this.context, true);
		const content = await client.ipsos.getActivityImage(id, { responseType: "arraybuffer" });

		const blob = new Blob([ content.data ], { type: "octet/stream" });
		const url  = window.URL.createObjectURL(blob);

		const anchor = document.createElement("a");
		document.body.appendChild(anchor);

		anchor.href = url;
		anchor.download = fileName;
		anchor.click();

		window.URL.revokeObjectURL(url);
	}

	render() {
		return (
			<>
				<DefaultManageLayout>
					<div className="mb-3">
						Kliknutím na řádek zobrazíte připojené soubory a podrobnosti.
					</div>

					<div className="ag-theme-balham" style={{height: 400, width: '100%'}}>
						<AgGridReact
							defaultColDef={this.state.defaultColDef}
							columnDefs={this.state.columnDefs}
							rowData={this.state.data}
							rowSelection="single"
							onGridReady={this.onGridReady.bind(this)}
							onSelectionChanged={this.onRowSelection.bind(this)}>
						</AgGridReact>
					</div>

					<div className="mt-3">
						Vyplněno dotazníků: {this.state.count}
					</div>

					{this.state.selectedRowId &&
						<Row className="mt-5">
							<Col xs={12} md={7}>
								<h4>Podrobnosti</h4>
								<div className="mb-3">
									<div><strong>Pocity</strong></div>
									<div>{this.state.selectedRow.impression}</div>
								</div>
								<div className="mb-3">
									<div><strong>Aktivita</strong></div>
									<div>{this.state.selectedRow.activity}</div>
								</div>
								<div className="mb-3">
									<div><strong>Lokalita</strong></div>
									<div>{this.state.selectedRow.place}</div>
								</div>
								<div className="mb-3">
									<div><strong>Náměty ke zlepšení</strong></div>
									<div>{this.state.selectedRow.feedback}</div>
								</div>
							</Col>

							<Col xs={12} md={5}>
								<h4>Připojené soubory ({this.state.files.length})</h4>
								<Alert variant="danger">Opatrně! Soubory nejsou kontrolovány a mohou mít škodlivý obsah.</Alert>

								<div>
									{this.state.files.map(f =>
										<div key={'file-' + f.id} className="mb-2">
											<Button variant="secondary" onClick={() => this.download(f!.id, f!.fileName ?? "")}>
												<i className="fa fa-solid fa-file me-2"></i> {f.fileName}
											</Button>
										</div>
									)}
								</div>
							</Col>
						</Row>
					}
				</DefaultManageLayout>
			</>
		)
	}
}
