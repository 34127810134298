import React from "react";
import {Button} from "react-bootstrap";
import {AppContext} from "./commons/context/AppContextProvider";
import "./commons/styles/forms.scss";
import DefaultLayout from "./commons/layout/DefaultLayout";
import {Redirect} from "react-router-dom";
import { authLoginRequest } from "./commons/auth/AuthConfiguration";

interface IState {
	message: { },
	redirect: boolean,
	loginHint?: string | null
}

interface IProps{
	location: {
		search: {}
	}
}

export default class SubmitSuccess extends React.Component<IProps, IState> {
	static contextType = AppContext;

	constructor(props: IProps) {
		super(props);
		const searchParams = new URLSearchParams(this.props.location.search);
		const formType = searchParams.get('formType') as string;
		const loginHint = searchParams.get('loginHint') as string;

		let message = <></>;
		if(formType === "0"){
			message = <>
				<h1 className="secondary">DĚKUJEME ZA VYPLNĚNÍ DOTAZNÍKU!</h1>
				<div>Tvé odpovědi jsme si zapsali. Těšíme se na fotky z tvého dobrovolnictví!</div>
				<div>V případě jakýchkoliv dotazů nás neváhej kontaktovat na e-mailové adrese <a href="mailto:info@maschvilku.cz">info@maschvilku.cz</a></div>
			</>
		}else if(formType === "1"){
			message = <>
				<h1 className="secondary">PARÁDA, MÁŠ HOTOVO!</h1>
				<div>Každá chvilka pomůže a přináší dobrý pocit.</div>
				<div>Děkujeme ti!</div>
				<div>Máš Chvilku & IPSOS</div>
			</>
		}else if(formType === "2"){
			message = <>
				<h1 className="secondary">DĚKUJEME ZA REGISTRACI!</h1>
				<div>Nyní se můžeš <a href="#" onClick={() => this.login()}>přihlásit</a></div>
				<div>Děkujeme ti!</div>
				<div>Máš Chvilku & IPSOS</div>
			</>
		}
		this.state = {
			message,
			redirect: false,
			loginHint
		};
	}

	async login() {
		const request = {
			scopes: authLoginRequest.scopes,
			loginHint: this.state.loginHint
		};

		await this.context.authClient.handleRedirectPromise();
		this.context.authClient.loginRedirect(request);
	}

	render() {
		if (this.state.redirect) {
			return <Redirect push to="/"/>
		}
		return (
			<DefaultLayout>
				<div className="row">
					<div className="mx-auto col-12 col-md-8 text-center">
						{this.state.message}
						<Button variant="primary" className="mt-3 btn btn-mch-secondary btn-mch-rounded" onClick={() => this.setState({redirect: true})}>
							ZPĚT NA DOMOVSKOU STRÁNKU
						</Button>
					</div>
				</div>
			</DefaultLayout>
		)
	}
}
