import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { AppContextProvider } from "./commons/context/AppContextProvider";
import AuthRouter from "./auth/AuthRouter";
import "./commons/styles/base.scss";
import LandingPage from "./landingpage/LandingPage";
import AuthenticatedRoute from "./commons/auth/AuthenticatedRoute";
import AfterActivityForm from "./AfterActivityForm";
import AfterRegistrationForm from "./AfterRegistrationForm";
import AfterActivityVerification from "./AfterActivityVerification";
import Registration from "./Registration";
import SubmitSuccess from "./SubmitSuccess";
import Manage from "./manage/Manage";
import ManageUsers from "./manage/ManageUsers";
import ManageAfterRegistration from "./manage/ManageAfterRegistration";
import ManageAfterActivity from "./manage/ManageAfterActivity";

ReactDOM.render(
	<React.StrictMode>
		<AppContextProvider>
			<Router>
				<Switch>
					<Route path="/auth" component={AuthRouter} />
					<AuthenticatedRoute path="/afterActivity" component={AfterActivityForm} />
					<AuthenticatedRoute path="/afterRegistration" component={AfterRegistrationForm} />
					<Route path="/confirmation" component={AfterActivityVerification} />
					<Route path="/registration" component={Registration} />
					<Route path="/submitSuccess" component={SubmitSuccess} />
					<AuthenticatedRoute path="/manage/users" component={ManageUsers} />
					<AuthenticatedRoute path="/manage/after-registration" component={ManageAfterRegistration} />
					<AuthenticatedRoute path="/manage/after-activity" component={ManageAfterActivity} />
					<AuthenticatedRoute path="/manage" component={Manage} />
					<Route path="/" component={LandingPage} />
				</Switch>
			</Router>
		</AppContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
