import React from "react";
import {Nav} from "react-bootstrap";
import DefaultLayout from "../commons/layout/DefaultLayout";
import {LinkContainer} from "react-router-bootstrap";
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";

interface IState {
	isAdmin: boolean | null;
}

export default class DefaultManageLayout extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);
		this.state = { isAdmin: null };
	}

	async componentDidMount() {
		await this.isAdmin();
	}

	async isAdmin() {
		let isAdmin = false;

		const client = await MasChvilkuApiClient.getInstance(this.context, true);
		try {
			const response = await client.ipsos.isAdmin();
			isAdmin = response.data.isAdmin ?? false;
		} catch(error) {
			// Nop
		}

		this.setState({ isAdmin });
	}

	public render() {
		return (
			<DefaultLayout>
				{this.state.isAdmin === false && <div>Neoprávněný přístup</div>}
				{this.state.isAdmin === true &&
					<>
						<Nav variant="pills">
							<Nav.Item>
								<LinkContainer to="/manage/after-registration">
									<Nav.Link>Dotazníky po registraci</Nav.Link>
								</LinkContainer>
							</Nav.Item>
							<Nav.Item>
								<LinkContainer to="/manage/after-activity">
									<Nav.Link>Dotazníky po aktivitě</Nav.Link>
								</LinkContainer>
							</Nav.Item>
							<Nav.Item>
								<LinkContainer to="/manage/users">
									<Nav.Link>Uživatelé</Nav.Link>
								</LinkContainer>
							</Nav.Item>
						</Nav>

						<div className="mt-5">
							{this.props.children}
						</div>
					</>
				}
			</DefaultLayout>
		)
	}
}
