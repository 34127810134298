import React from "react";
import { authLoginRequest } from "../commons/auth/AuthConfiguration";
import { AppContext } from "../commons/context/AppContextProvider";

export default class AuthLogin extends React.Component {
	static contextType = AppContext;

	async componentDidMount() {
		await this.context.authClient.handleRedirectPromise();
		this.context.authClient.loginRedirect(authLoginRequest);
	}

	render() {
		return (<div>Načítám...</div>);
	}
}
