import React from "react";
import {Card, Col, Row} from "react-bootstrap";

import "../commons/styles/forms.scss";
import DefaultManageLayout from "./DefaultManageLayout";
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";
import _ from "lodash";

interface IState {
	countUsers: number;
	countAfterRegistration: number;
	countAfterActivity: number;
}

export default class Manage extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);

		this.state = {
			countUsers: 0,
			countAfterRegistration: 0,
			countAfterActivity: 0
		};
	}

	async componentDidMount() {
		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const usersResponse = await client.ipsos.countUsers();
		const countUsers = usersResponse.data?.count ?? 0;
		this.setState({ countUsers });

		const afterRegistrationResponse = await client.ipsos.countIntroductionQuestionnaires();
		const countAfterRegistration = afterRegistrationResponse.data?.count ?? 0;
		this.setState({ countAfterRegistration });

		const afterActivityResponse = await client.ipsos.countActivityQuestionnaires();
		const countAfterActivity = afterActivityResponse.data?.count ?? 0;
		this.setState({ countAfterActivity });
	}

	render() {
		return (
			<>
				<DefaultManageLayout>
					<Row>
						<Col>
							<Card>
								<Card.Body>
									<Card.Text>
										<div>Počet uživatelů</div>
										<h2 className="mt-3">{this.state.countUsers}</h2>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card>
								<Card.Body>
									<Card.Text>
										<div>Počet vyplněných dotazníků po registraci</div>
										<h2 className="mt-3">{this.state.countAfterRegistration}</h2>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col>
							<Card>
								<Card.Body>
									<Card.Text>
										<div>Počet vyplněných dotazníků po aktivitě</div>
										<h2 className="mt-3">{this.state.countAfterActivity}</h2>
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</DefaultManageLayout>
			</>
		)
	}
}
