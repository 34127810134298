import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";

import AuthLogin from "./AuthLogin";
import AuthCallback from "./AuthCallback";
import AuthLogout from "./AuthLogout";
import SilentAuthCallback from "./SilentAuthCallback";
import AuthLogoutCallback from "./AuthLogoutCallback";

class AuthRouter extends React.Component<any> {
	render() {
		const baseUrl = this.props.match.url;

		return (
			<Switch>
				<Route path={baseUrl + '/login'} component={AuthLogin} />
				<Route path={baseUrl + '/callback'} component={AuthCallback} />
				<Route path={baseUrl + '/silent-callback'} component={SilentAuthCallback} />
				<Route path={baseUrl + '/logout'} component={AuthLogout} />
			</Switch>
		);
	}
}

export default withRouter(AuthRouter);
