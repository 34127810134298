import React from "react";
import {LinkContainer} from 'react-router-bootstrap';
import {Button, Container, Nav, Navbar, Image, NavDropdown} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import {AppContext} from "../context/AppContextProvider";
import { authLoginRequest } from "../auth/AuthConfiguration";

import "./navtopbar.scss";
import HorizontalLogo from "../../assets/images/logo-horizontal.svg";
import MasChvilkuApiClient from "../../api";

interface IProps {
	hideLogoOnSmallScreen?: boolean;
}

interface IState {
	isLoggedIn: boolean;
	userFirstName?: string;
	userLastName?: string;
	isAdmin: boolean;
}

export default class NavBarTop extends React.Component<IProps, IState> {
	static contextType = AppContext;

	constructor(props: IProps) {
		super(props);

		this.state = {
			isLoggedIn: false,
			isAdmin: false
		};
	}

	async componentDidMount() {
		const account = this.context.authClient.getActiveAccount();
		const isLogged = account !== null;
		this.setState({ isLoggedIn: isLogged });

		if (isLogged) {
			const userFirstName = account?.idTokenClaims?.given_name;
			const userLastName = account?.idTokenClaims?.family_name;

			this.setState({
				userFirstName: userFirstName,
				userLastName
			});
		}

		await this.isAdmin();
	}

	async login() {
		await this.context.authClient.handleRedirectPromise();
		this.context.authClient.loginRedirect(authLoginRequest);
	}

	async isAdmin() {
		let isAdmin = false;

		const client = await MasChvilkuApiClient.getInstance(this.context, true);
		try {
			const response = await client.ipsos.isAdmin();
			isAdmin = response.data.isAdmin ?? false;
		} catch(error) {
			// Nop
		}

		this.setState({ isAdmin });
	}

	render() {
		const logoClasses = this.props.hideLogoOnSmallScreen ? "d-none d-lg-inline" : "";

		return (
			<Navbar bg="transparent" expand="lg" className="py-4">
				<Container>
					<LinkContainer to="/">
						<Navbar.Brand>
							<Image src={HorizontalLogo} height="75" width="150" className={logoClasses} />
						</Navbar.Brand>
					</LinkContainer>

					<NavbarToggle aria-controls="navbar-nav" />

					<Navbar.Collapse id="navbar-nav" className="ms-auto me-auto">
						<Nav className="nav-links">
							{this.renderNavigation()}
						</Nav>


						<Nav className="ms-auto">
							{this.renderProfile()}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		)
	}

	renderProfile(){
		if(!this.state.isLoggedIn){
			return (<>
				<Button className="btn-login" onClick={() => this.login()}>Přihlášení</Button>

				<Button className="btn-mch-secondary btn-mch-rounded btn-registration"
						href="Registration">
					Registrace
				</Button>
			</>);
		}

		return (<>
			<LinkContainer to="/">
				<Nav.Link>{this.state.userFirstName} {this.state.userLastName}</Nav.Link>
			</LinkContainer>

			<LinkContainer to="/auth/logout">
				<Nav.Link>Odhlásit se</Nav.Link>
			</LinkContainer>
		</>);
	}

	renderNavigation() {
		if(!this.state.isLoggedIn){
			return null;
		}
		return (<>
			<LinkContainer to="/afterRegistration">
				<Nav.Link>Dotazník po registraci</Nav.Link>
			</LinkContainer>
			<LinkContainer to="/afterActivity">
				<Nav.Link>Dotazník po aktivitě</Nav.Link>
			</LinkContainer>
			{this.state.isAdmin &&
				<LinkContainer to="/manage">
					<Nav.Link>Správa</Nav.Link>
				</LinkContainer>
			}
			</>);
	}
}
