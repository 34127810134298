import React from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {AppContext} from "./commons/context/AppContextProvider";
import "./commons/styles/forms.scss";
import DefaultLayout from "./commons/layout/DefaultLayout";
import MasChvilkuApiClient from "./api";
import {Redirect} from "react-router-dom";

interface IState {
	name: string,
	surname: string,
	email: string,
	password: string,
	confirmPassword: string,
	department: string,
	submitSuccess: boolean,
	submitError: boolean,
	isLoading: boolean,
	formError: boolean,
	formErrorMessage: string,
	duplicateUser: boolean
	gdpr: boolean
}

export default class Registration extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);
		this.state = {
			name: "",
			surname: "",
			email: "",
			password: "",
			confirmPassword: "",
			department: "",
			submitSuccess: false,
			submitError: false,
			isLoading: false,
			formError: false,
			formErrorMessage: "",
			duplicateUser: false,
			gdpr: false,
		};
	}

	handleInputChange = (event: any) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name as string;
		const newState = { [name]: value } as Pick<IState, keyof IState>;
		this.setState(newState);
	}

	handleSubmit = async () => {
		this.setState({submitSuccess: false, submitError: false, isLoading: true, formError: false});

		if(!this.state.gdpr){
			this.setState({isLoading: false, formError: true, formErrorMessage:"Musíš souhlasit s podmínkami zpracování osobních údajů"});
			return;
		}

		if(!this.state.name || !this.state.surname || !this.state.email || !this.state.password || !this.state.department){
			this.setState({isLoading: false, formError: true, formErrorMessage:"Vyplň všechny otázky označené *"});
			return;
		}

		if(this.state.password !== this.state.confirmPassword){
			this.setState({isLoading: false, formError: true, formErrorMessage:"Hesla se neshodují"});
			return;
		}

		if(this.state.password.length < 6){
			this.setState({isLoading: false, formError: true, formErrorMessage:"Heslo musí obsahovat minimálně 6 znaků"});
			return;
		}

		const client = await MasChvilkuApiClient.getInstance(this.context, false);

		const request = {
			email: this.state.email,
			password: this.state.password,
			confirmPassword: this.state.confirmPassword,
			firstName: this.state.name,
			lastName: this.state.surname,
			department: this.state.department,
		}

		try {
			await client.ipsos.register(request);
			this.setState({submitSuccess: true});
		} catch (e: any) {
			console.log(e);
			if (e?.response && e.response.status === 409) {
				this.setState({duplicateUser: true});
			} else {
				this.setState({submitError: true});
			}
		} finally {
			this.setState({isLoading: false});
		}
	}

	render() {
		if(this.state.submitSuccess){
			return <Redirect push to={'/submitSuccess?formType=2&loginHint=' + encodeURIComponent(this.state.email)}/>
		}
		return (
			<DefaultLayout>
				<div className="row form-style">
					<div className="mx-auto col-12 col-md-8">
						<h1 className="secondary">Registrace</h1>
						<span className="form-label">Pole označená * jsou povinná</span>
						<Form className="mt-3">
							<Row className="mb-3">
								<Form.Group controlId="formName" >
									<Form.Label>Jméno*</Form.Label>
									<Form.Control name="name" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formSurname" >
									<Form.Label>Příjmení*</Form.Label>
									<Form.Control name="surname" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formEmail" >
									<Form.Label>E-mail (přihlašovací jméno)*</Form.Label>
									<Form.Control name="email" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formPassword" >
									<Form.Label>Heslo*</Form.Label>
									<Form.Control type="password" name="password" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formPasswordRepeat" >
									<Form.Label>Heslo znovu*</Form.Label>
									<Form.Control type="password" name="confirmPassword" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formDepartment" >
									<Form.Label>Oddělení*</Form.Label>
									<Form.Control name="department" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="gdpr">
									<Form.Check name="gdpr" onChange={this.handleInputChange} checked={this.state.gdpr === true}
												label={<>Souhlasím s <a href="/docs/GDPR-maschvilku.docx" className="link no-underline" title="Zpracování osobních údajů">podmínkami zpracování osobních údajů</a>*</>}>
									</Form.Check>
								</Form.Group>
							</Row>
							<Row>
								<Col xs={4} className="mx-auto text-center">
									<Button variant="primary" className="btn btn-mch-secondary btn-mch-rounded mx-auto w-100" onClick={this.handleSubmit} disabled={this.state.isLoading}>
										Registrovat
									</Button>

									<div className="mt-3">
										{this.state.isLoading ? <Spinner animation="border" role="status" >
											<span className="visually-hidden">Loading...</span>
										</Spinner> : null}

										{this.state.submitSuccess ? <span className="submit-info">Účet vytvořen</span> : null}
										{this.state.submitError ? <span className="submit-error">Nepodařilo se odeslat formulář</span> : null}
										{this.state.formError ? <span className="submit-error">{this.state.formErrorMessage}</span> : null}
										{this.state.duplicateUser ? <span className="submit-error">Účet již existuje</span> : null}
									</div>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</DefaultLayout>
		)
	}
}
