import React from "react";
import { AppContext } from "../commons/context/AppContextProvider";

export default class AuthLogout extends React.Component {
	static contextType = AppContext;

	async componentDidMount() {
		await this.context.authClient.logoutRedirect({
			postLogoutRedirectUri: "https://ipsos.maschvilku.cz"
		});
	}

	render() {
		return (<div>Probíhá odhlašování...</div>);
	}
}
