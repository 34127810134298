import React from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {AppContext} from "./commons/context/AppContextProvider";
import "./commons/styles/forms.scss";
import DefaultLayout from "./commons/layout/DefaultLayout";
import MasChvilkuApiClient from "./api";

interface IState {
	organization: string,
	name: string,
	contact: string,
	confirmation: boolean,
	submitSuccess: boolean,
	submitError: boolean,
	isLoading: boolean,
}

interface IProps{
	location: {
		search: {}
	}
}

export default class AfterActivityVerification extends React.Component<IProps, IState> {
	static contextType = AppContext;

	constructor(props: IProps) {
		super(props);
		this.state = {
			organization: "",
			name: "",
			contact: "",
			confirmation: false,
			submitSuccess: false,
			submitError: false,
			isLoading: false,
		};
	}

	handleInputChange = (event: any) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name as string;
		const newState = { [name]: value } as Pick<IState, keyof IState>;
		this.setState(newState);
	}

	handleSubmit = async () => {
		if(!this.state.confirmation){
			return;
		}

		this.setState({submitSuccess: false, submitError: false, isLoading: true});

		const searchParams = new URLSearchParams(this.props.location.search);
		const id = searchParams.get('id') as string;
		const token = searchParams.get('token') as string;

		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const request = {
			organizationName: this.state.organization,
			organizationContactPerson: this.state.name,
			organizationContact:  this.state.contact,
		}

		try {
			await client.ipsos.confirmActivity(id, token, request);
			this.setState({submitSuccess: true});
		} catch (e) {
			console.error(e);
			this.setState({submitError: true});
		} finally {
			this.setState({isLoading: false});
		}
	}

	render() {
		return (
			<DefaultLayout>
				<div className="row form-style">
					<div className="mx-auto col-12 col-md-8">
						<h1 className="secondary">Vyplňte, prosím, následující dotazník.</h1>
						<Form>
							<Row className="mb-3">
								<Form.Group controlId="formOrganization" >
									<Form.Label>Název organizace:</Form.Label>
									<Form.Control name="organization" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formName" >
									<Form.Label>Jméno a příjmení kontaktní osoby:</Form.Label>
									<Form.Control name="name" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formContact" >
									<Form.Label>Kontakt (e-mail či telefon):</Form.Label>
									<Form.Control name="contact" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formConfirmation" >
									<Form.Check
										id="confirmation"
										name="confirmation"
										onChange={this.handleInputChange}
										label="Potvrzuji, že u nás vykonával dobrovolnickou aktivitu zaměstnanec společnosti IPSOS."
									/>
								</Form.Group>
							</Row>
							<Row>
								<Col xs={4} className="mx-auto text-center">
									<Button variant="primary" className="btn btn-mch-secondary btn-mch-rounded w-100" onClick={this.handleSubmit} disabled={this.state.isLoading}>
										Odeslat
									</Button>
									<div className="mt-3">
										{this.state.isLoading ? <Spinner animation="border" role="status" >
											<span className="visually-hidden">Loading...</span>
										</Spinner> : null}

										{this.state.submitSuccess ? <span className="submit-info">Odesláno</span> : null}
										{this.state.submitError ? <span className="submit-error">Nepodařilo se odeslat formulář</span> : null}
									</div>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</DefaultLayout>
		)
	}
}


