import React, {MouseEvent} from "react";
import {Col, Container, Row} from "react-bootstrap";

import "./footer.scss";

import FacebookIcon from "../../assets/images/social/facebook.svg";
import InstagramIcon from "../../assets/images/social/instagram.svg";

import MailIcon from "../../assets/images/mail.svg";

interface IProps {
	hideJumpToTop?: boolean;
}

export default class Footer extends React.Component<IProps, {}> {
	private currentYear = new Date().getFullYear();

	render() {
		return (
			<React.Fragment>
				{!this.props.hideJumpToTop &&
					<div className="jump-to-top">
						<a href="#top" onClick={this.jumpToTop} title="Návrat na začátek stránky">
							<i className="fas fa-chevron-up" />
						</a>
					</div>
				}

				<footer>
					<Container>
						<Row className="pt-5 pb-3 mb-md-4 align-items-center">
							<Col lg className="text-center mb-4 mb-lg-0">
								{this.renderSocialNetworkLinks()}
							</Col>

							<Col lg="auto">
								{this.renderContacts()}
							</Col>

							<Col lg className="text-center order-lg-first">
								<ul className="list-unstyled m-0">
									<li>
										<a href="/docs/GDPR-maschvilku.docx" className="link no-underline" title="Zpracování osobních údajů">
											Zpracování osobních údajů
										</a>
									</li>
								</ul>
							</Col>
						</Row>

						<div className="copyright py-3 text-center">
							Copyright &copy; 2020&ndash;{this.currentYear} MášChvilku.cz. Všechna práva vyhrazena.
						</div>
					</Container>
				</footer>
			</React.Fragment>
		);
	}

	jumpToTop(e: MouseEvent) {
		e.preventDefault();
		window.scrollTo(0, 0);
	}

	renderSocialNetworkLinks() {
		return (
			<ul className="social-networks">
				{this.renderSocialNetworkLink(FacebookIcon, 'Facebook', 'https://www.facebook.com/maschvilku')}
				{this.renderSocialNetworkLink(InstagramIcon, 'Instagram', 'https://www.instagram.com/maschvilku')}
				{/*{this.renderSocialNetworkLink(LinkedInIcon, 'LinkedIn', 'https://www.linkedin.com')}*/}
			</ul>
		);
	}

	renderSocialNetworkLink(icon: any, name: string, url: string) {
		return (
			<li>
				<a href={url} title={name}>
					<img alt={name} src={icon} width={48} height={48} />
				</a>
			</li>
		);
	}

	renderContacts() {
		return (
			<Row className="justify-content-center align-items-center">
				{this.renderContact(MailIcon, 'E-mail', 42, 30, 'info@maschvilku.cz', 'mailto:info@maschvilku.cz')}
			</Row>
		);
	}

	renderContact(icon: any, alt: string, width: number, height: number, text: string, linkHref?: string) {
		const displayText = linkHref ? <a href={linkHref} className="link no-underline">{text}</a> : text;

		return (
			<Col xs="auto" className="me-sm-3 me-lg-1 me-xl-4 mb-4 mb-lg-0">
				<Row className="align-items-center">
					<Col xs="auto" className="p-0 icon"><img src={icon} width={width} height={height} alt={alt} /></Col>
					<Col xs="auto" className="ps-3">
						{displayText}
					</Col>
				</Row>
			</Col>
		);
	}
}
