import { InteractionRequiredAuthError } from "@azure/msal-browser";
import React from "react";
import {Route} from "react-router-dom";
import {AppContext} from "../context/AppContextProvider";
import { authScopes } from "./AuthConfiguration";

interface IState {
	loaded: boolean;
}

export default class AuthenticatedRoute extends React.Component<any, IState> {
	static contextType = AppContext;

	constructor(props: any) {
		super(props);

		this.state = { loaded: false };
	}

	async componentDidMount() {
		await this.context.authClient.handleRedirectPromise();

		let token;
		try {
			token = await this.context.authClient.acquireTokenSilent(authScopes);
		} catch (e) {
			if (e instanceof InteractionRequiredAuthError) {
				this.context.authClient.acquireTokenRedirect(authScopes);
				return;
			}
		}

		if (!token) {
			this.context.authClient.acquireTokenRedirect(authScopes);
			return;
		}

		this.setState({ loaded: true });
	}

	render() {
		const { component: Component, ...otherProps } = this.props;
		return (
			<React.Fragment>
				{this.state.loaded && <Route {...otherProps} render={routeProps => <Component {...routeProps} />} />}
				{!this.state.loaded && <div>Načítám...</div>}
			</React.Fragment>
		);
	}
}
