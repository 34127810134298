import React from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {AppContext} from "./commons/context/AppContextProvider";
import "./commons/styles/forms.scss";
import DefaultLayout from "./commons/layout/DefaultLayout";
import MasChvilkuApiClient from "./api";
import {Redirect} from "react-router-dom";

interface IState {
	customActivity: boolean | null,
	customOrganization: string | null,
	preferNature: boolean,
	preferAnimals: boolean,
	preferChildren: boolean,
	preferElder: boolean,
	preferEmergency: boolean,
	excludeNature: boolean,
	excludeAnimals: boolean,
	excludeChildren: boolean,
	excludeElder: boolean,
	excludeEmergency: boolean,
	individual: boolean | null,
	accompanion: string | null,
	preferredWeek: string | null,
	benefitRating: string | null,
	socialResponsibility: boolean | null,
	submitSuccess: boolean,
	submitError: boolean,
	isLoading: boolean,
	formError: boolean,
	formLoading: boolean,
	formLoadingError: boolean,
	hasAnswered: boolean,
}

export default class AfterRegistrationForm extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);
		this.state = {
			customActivity: null,
			customOrganization: null,
			preferNature: false,
			preferAnimals: false,
			preferChildren: false,
			preferElder: false,
			preferEmergency: false,
			excludeNature: false,
			excludeAnimals: false,
			excludeChildren: false,
			excludeElder: false,
			excludeEmergency: false,
			individual: null,
			accompanion: null,
			preferredWeek: null,
			benefitRating: null,
			socialResponsibility: null,
			submitSuccess: false,
			submitError: false,
			isLoading: false,
			formError: false,
			formLoading: false,
			formLoadingError: false,
			hasAnswered: true
		};
	}

	async componentDidMount() {
		await this.loadForm();
	}

	loadForm = async () => {
		this.setState({formLoading: true, formLoadingError: false});
		const client = await MasChvilkuApiClient.getInstance(this.context, true);
		try {

			const result = await client.ipsos.hasAnsweredIntroductionQuestionnaire();
			this.setState({hasAnswered: result.data});
		} catch (e) {
			console.error(e);
			this.setState({formLoadingError: true});
		} finally {
			this.setState({formLoading: false});
		}
	}

	handleInputChange = (event: any) => {
		const target = event.target;
		if(target.type === 'radio'){
			const parsed = target.name.split("-");
			const name = parsed[0];
			const value = parsed[1] === "yes" ? true : parsed[1] === "no" ? false : parsed[1];

			const newState = { [name]: value } as Pick<IState, keyof IState>;
			this.setState(newState);
		}
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name as string;

		const newState = { [name]: value } as Pick<IState, keyof IState>;
		this.setState(newState);
	}

	handleSubmit = async () => {
		this.setState({submitSuccess: false, submitError: false, isLoading: true, formError: false});

		if(this.state.customActivity === null || this.state.benefitRating === null/* || this.state.socialResponsibility === null*/){
			this.setState({isLoading: false, formError: true});
			return;
		}

		if(this.state.customActivity === false){
			if(this.state.individual === null || this.state.preferredWeek === null){
				this.setState({isLoading: false, formError: true});
				return;
			}

			if(this.state.individual === false && !this.state.accompanion){
				this.setState({isLoading: false, formError: true});
				return;
			}
		}
		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const request = {
			customActivity: this.state.customActivity as boolean,
			customOrganization: this.state.customOrganization as string,
			preferNature: this.state.preferNature as boolean,
			preferAnimals: this.state.preferAnimals as boolean,
			preferChildren: this.state.preferChildren as boolean,
			preferElder: this.state.preferElder as boolean,
			preferEmergency: this.state.preferEmergency as boolean,
			excludeNature: this.state.excludeNature as boolean,
			excludeAnimals: this.state.excludeAnimals as boolean,
			excludeChildren: this.state.excludeChildren as boolean,
			excludeElder: this.state.excludeElder as boolean,
			excludeEmergency: this.state.excludeEmergency as boolean,
			individual: this.state.individual as boolean,
			accompanion: this.state.accompanion as string,
			preferedWeek: Number(this.state.preferredWeek),
			benefitOpinionRank: Number(this.state.benefitRating),
			socialResponsibility: this.state.socialResponsibility as boolean
		}

		try {
			await client.ipsos.saveIntroductionQuestionnaire(request);
			this.setState({submitSuccess: true});
		} catch (e) {
			console.error(e);
			this.setState({submitError: true});
		} finally {
			this.setState({isLoading: false});
		}
	}

	render() {
		if(this.state.submitSuccess){
			return <Redirect push to="/submitSuccess?formType=0"/>
		}

		if(this.state.formLoading){
			return (<DefaultLayout>
				<div className="row">
					<div className="mx-auto col-12 col-md-8 text-center">
						<Spinner animation="border" role="status" >
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				</div>
			</DefaultLayout>);
		}

		if(this.state.formLoadingError){
			return (<DefaultLayout>
				<div className="row">
					<div className="mx-auto col-12 col-md-8 text-center">
						<div>Nepodařilo se načíst formulář.</div>
						<Button variant="primary" className="mt-3 btn btn-mch-secondary btn-mch-rounded" onClick={async () => await this.loadForm()}>
							Zkusit znovu
						</Button>
					</div>
				</div>
			</DefaultLayout>)
		}

		if(this.state.hasAnswered){
			return (<DefaultLayout>
				<div className="row">
					<div className="mx-auto col-12 col-md-8 text-center">
						<h1 className="secondary">Tento dotazník jste již vyplnil/a.</h1>
					</div>
				</div>
			</DefaultLayout>)
		}

		return (
			<DefaultLayout>
				<div className="row form-style">
					<div className="mx-auto col-12 col-md-8">
						<h1 className="secondary">Vyplň, prosím, následující dotazník.</h1>
						<span className="form-label">Pole označená * jsou povinná</span>
						<Form className="mt-3">
							<Row className="mb-3">
								<Form.Group controlId="formCustomActivity" >
									<Form.Label>Jak se plánuješ zapojit do dobrovolnictví?*</Form.Label>
									<Form.Check
										type="radio"
										label="Domluvím si dobrovolnickou aktivitu po vlastní ose (např. mám oblíbenou organizaci, kde již pomáhám, kde se domluvím atd.)"
										name="customActivity-yes"
										id="customActivity-yes"
										checked={this.state.customActivity === true}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Využiji zprostředkování přes máš·chvilku"
										name="customActivity-no"
										id="customActivity-no"
										checked={this.state.customActivity === false}
										onChange={this.handleInputChange}
									/>
								</Form.Group>
							</Row>
							{this.state.customActivity ?
								<Row className="mb-3">
									<Form.Group controlId="formCustomOrganization" >
										<Form.Label>Vypiš o jakou organizaci se jedná.</Form.Label>
										<Form.Control name="customOrganization" onChange={this.handleInputChange} />
									</Form.Group>
								</Row> : null
							}
							{this.state.customActivity === false ?
								<>
									<Row className="mb-3">
										<Form.Group controlId="formAreas" >
											<Form.Label>Vyber následující oblasti dobrovolnictví, o které máš největší zájem.</Form.Label>
											<Form.Check
												id="preferNature"
												name="preferNature"
												onChange={this.handleInputChange}
												label="Příroda (sbírání odpadků, čištění potoků, sázení stromů atd.)"
												checked={this.state.preferNature === true}
											/><Form.Check
											id="preferAnimals"
											name="preferAnimals"
											onChange={this.handleInputChange}
											label="Zvířata (útulky, zvířecí depozita, chráněné farmy atd.)"
											checked={this.state.preferAnimals === true}
										/><Form.Check
											id="preferChildren"
											name="preferChildren"
											onChange={this.handleInputChange}
											label="Děti (doučování, hraní her, začleňování do společnosti atd.)"
											checked={this.state.preferChildren === true}
										/><Form.Check
											id="preferElder"
											name="preferElder"
											onChange={this.handleInputChange}
											label="Senioři (povídání, předčítání, procházky atd.)"
											checked={this.state.preferElder === true}
										/><Form.Check
												id="preferEmergency"
												name="preferEmergency"
												onChange={this.handleInputChange}
												label="Krizové situace (živelné pohromy, pandemie, pomoc uprchlíkům atd.)"
												checked={this.state.preferEmergency === true}
										/>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Group controlId="formAreas" >
											<Form.Label>Je naopak nějaká oblast, o kterou vyloženě nemáš zájem? (Třeba kvůli alergii na zvířata nebo děti 😊)</Form.Label>
											<Form.Check
												id="excludeNature"
												name="excludeNature"
												onChange={this.handleInputChange}
												label="Rozhodně nemám zájem o dobrovolnictví v přírodě"
												checked={this.state.excludeNature === true}
											/><Form.Check
											id="excludeAnimals"
											name="excludeAnimals"
											onChange={this.handleInputChange}
											label="Rozhodně nemám zájem o dobrovolnictví se zvířaty"
											checked={this.state.excludeAnimals === true}
										/><Form.Check
											id="excludeChildren"
											name="excludeChildren"
											onChange={this.handleInputChange}
											label="Rozhodně nemám zájem o dobrovolnictví s dětmi"
											checked={this.state.excludeChildren === true}
										/><Form.Check
											id="excludeElder"
											name="excludeElder"
											onChange={this.handleInputChange}
											label="Rozhodně nemám zájem o dobrovolnictví se seniory"
											checked={this.state.excludeElder === true}
										/>
											<Form.Check
												id="excludeEmergency"
												name="excludeEmergency"
												onChange={this.handleInputChange}
												label="Rozhodně nemám zájem o dobrovolnictví v krizových situacích"
												checked={this.state.excludeEmergency === true}
											/>
										</Form.Group>
									</Row>

									<Row className="mb-3">
										<Form.Group controlId="formIndividual" >
											<Form.Label>Půjdeš na aktivitu sám/sama (případně s někým mimo firmu), nebo s týmem?*</Form.Label>
											<Form.Check
												type="radio"
												label="Ano - půjdu sám/sama"
												name="individual-yes"
												id="individual-yes"
												checked={this.state.individual === true}
												onChange={this.handleInputChange}
											/>
											<Form.Check
												type="radio"
												label="Ne - půjdu s týmem"
												name="individual-no"
												id="individual-no"
												checked={this.state.individual === false}
												onChange={this.handleInputChange}
											/>
										</Form.Group>
									</Row>
									{this.state.individual === false ?
										<Row className="mb-3">
											<Form.Group controlId="formAccompanion" >
												<Form.Label>Prosíme vypiš jména, s kým půjdeš na aktivitu, nebo si hromadně zvolte název týmu.*</Form.Label>
												<Form.Control name="accompanion" onChange={this.handleInputChange} />
											</Form.Group>
										</Row> : null
									}
									<Row className="mb-3">
										<Form.Group controlId="formPreferredWeek" >
											<Form.Label>Vyber preferovaný termín, kdy chceš jít pomáhat.*</Form.Label>
											<Form.Select name="preferredWeek" onChange={this.handleInputChange}>
												<option>Vyber ze seznamu</option>
												<option value="0">25.4. - 1.5.2022</option>
												<option value="1">2.5. - 8.5.2022</option>
												<option value="2">9.5. - 15.5.2022</option>
												<option value="3">16.5. - 22.5.2022</option>
												<option value="4">23.5. - 29.5.2022</option>
												<option value="5">30.5. - 5.6.2022</option>
												<option value="6">6.6. - 12.6.2022</option>
												<option value="7">13.6. - 19.6.2022</option>
												<option value="8">20.6. - 26.6.2022</option>
												<option value="9">27.6. - 3.7.2022</option>
												<option value="10">4.7. - 10.7.2022</option>
												<option value="11">11.7. - 17.7.2022</option>
												<option value="12">18.7. - 24.7.2022</option>
												<option value="13">25.7. - 31.7.2022</option>
												<option value="14">1.8. - 7.8.2022</option>
												<option value="15">8.8. - 14.8.2022</option>
												<option value="16">15.8. - 21.8.2022</option>
												<option value="17">22.8. - 28.8.2022</option>
												<option value="18">Nevím, potřebuji řešit individuálně.</option>
											</Form.Select>
										</Form.Group>
									</Row>
								</>
								: null
							}
							<Row className="mb-3">
								<Form.Group controlId="formBenefitRating" >
									<Form.Label>Jak hodnotíš benefit jednoho proplaceného pracovního dne věnovaného dobrovolnictví?*</Form.Label>
									<Form.Check
										type="radio"
										label="Velice pozitivně"
										name="benefitRating-4"
										id="benefitRating-4"
										checked={this.state.benefitRating === "4"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše pozitivně"
										name="benefitRating-3"
										id="benefitRating-3"
										checked={this.state.benefitRating === "3"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Neutrálně"
										name="benefitRating-2"
										id="benefitRating-2"
										checked={this.state.benefitRating === "2"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše negativně"
										name="benefitRating-1"
										id="benefitRating-1"
										checked={this.state.benefitRating === "1"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Velice negativně"
										name="benefitRating-0"
										id="benefitRating-0"
										checked={this.state.benefitRating === "0"}
										onChange={this.handleInputChange}
									/>
								</Form.Group>
							</Row>
							{/* <Row className="mb-3">
								<Form.Group controlId="formResponsibility" >
									<Form.Label>Bereš proplácené pracovní dny na dobrovolnictví jako zapojování se firmy IPSOS do společenské odpovědnosti?*</Form.Label>
									<Form.Check
										type="radio"
										label="Ano"
										name="socialResponsibility-yes"
										id="socialResponsibility-yes"
										checked={this.state.socialResponsibility === true}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Ne"
										name="socialResponsibility-no"
										id="socialResponsibility-no"
										checked={this.state.socialResponsibility === false}
										onChange={this.handleInputChange}
									/>
								</Form.Group>
							</Row> */}
							<Row>
								<Col xs={4} className="mx-auto text-center">
									<Button variant="primary" className="btn btn-mch-secondary btn-mch-rounded mx-auto w-100" onClick={this.handleSubmit} disabled={this.state.isLoading}>
										Uložit
									</Button>
									<div className="mt-3">
										{this.state.isLoading ? <Spinner animation="border" role="status" >
											<span className="visually-hidden">Loading...</span>
										</Spinner> : null}

										{this.state.submitError ? <span className="submit-error">Nepodařilo se odeslat formulář</span> : null}
										{this.state.formError ? <span className="submit-error">Vyplň všechny otázky označené *</span> : null}
									</div>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</DefaultLayout>
		)
	}
}
