import JanAvatar from "../assets/images/avatars/jan.png";
import KubaAvatar from "../assets/images/avatars/kuba.png";
import AnickaAvatar from "../assets/images/avatars/anicka.png";
import MarekAvatar from "../assets/images/avatars/marek.png";
import MisaAvatar from "../assets/images/avatars/misa.png";
import LukasAvatar from "../assets/images/avatars/lukas.png";

const teamMembers = [
	{ name: "Honza", bio: "člověk s nápadem", avatar: JanAvatar },
	{ name: "Kuba", bio: "člověk přes kontakty", avatar: KubaAvatar },
	{ name: "Anička", bio: "člověk přes grafiku", avatar: AnickaAvatar },
	{ name: "Marek", bio: "člověk přes finance", avatar: MarekAvatar },
	{ name: "Míša", bio: "člověk přes vývoj", avatar: MisaAvatar },
	{ name: "Lukáš", bio: "člověk přes vývoj", avatar: LukasAvatar },
];

export default teamMembers;
