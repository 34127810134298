import React from "react";
import { AppContext } from "../commons/context/AppContextProvider";

export default class SilentAuthCallback extends React.Component {
	static contextType = AppContext;

	async componentDidMount() {
		await this.context.auth.handleSilentLoginCallback();
	}

	render() {
		return (
			<React.Fragment>
				<div>Probíhá přihlašování...</div>
			</React.Fragment>
		);
	}
}
