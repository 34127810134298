import axios from "axios";
import AppConfiguration from "./models/AppConfiguration";
import IConfigurationService from "./IConfigurationService";

export default class ConfigurationService implements IConfigurationService {
	private static config?: AppConfiguration;

	public async get(): Promise<AppConfiguration> {
		if (ConfigurationService.config === undefined) {
			const result = await axios.get("/config.json");
			ConfigurationService.config = result.data;
		}

		return ConfigurationService.config!;
	}
}
