import React from "react";

import "../commons/styles/forms.scss";
import DefaultManageLayout from "./DefaultManageLayout";
import {AgGridReact} from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";
import {IpsosEmployeeResponse} from "../api/client";
import _ from "lodash";

interface IState {
	count: number;
	users?: IpsosEmployeeResponse[];
	columnDefs: any;
	defaultColDef: any;
}

export default class ManageUsers extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);

		this.state = {
			count: 0,
			users: [],
			columnDefs: [
				{
					field: "id",
					headerName: "ID",
					width: 100
				},
				{
					field: "lastName",
					headerName: "Příjmení"
				},
				{
					field: "firstName",
					headerName: "Jméno"
				},
				{
					field: "email",
					headerName: "E-mail",
					width: 300
				},
				{
					field: "department",
					headerName: "Oddělení",
					width: 300
				},
			],
			defaultColDef: {
				sortable: true,
				resizable: true,
				width: 200
			}
		};
	}

	async componentDidMount() {
		const client = await MasChvilkuApiClient.getInstance(this.context, true);

		const usersResponse = await client.ipsos.getUsers();
		const users = _.sortBy(usersResponse.data, u => u.lastName, u => u.firstName);
		this.setState({ users });
	}

	render() {
		return (
			<>
				<DefaultManageLayout>
					<div className="ag-theme-balham" style={{height: 600, width: '100%'}}>
						<AgGridReact
							defaultColDef={this.state.defaultColDef}
							columnDefs={this.state.columnDefs}
							rowData={this.state.users}
							colResizeDefault="shift">
						</AgGridReact>
					</div>
				</DefaultManageLayout>
			</>
		)
	}
}
