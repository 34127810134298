export const authConfiguration = {
	auth: {
		authority: "https://id.maschvilku.cz/27fe19b4-b861-4292-a2f8-66980024528b/B2C_1A_SIGNIN",
		knownAuthorities: [ "id.maschvilku.cz" ],
		clientId: "afc0d5d5-940a-4f42-a660-3ac3594de3b9",
		redirectUri: "https://ipsos.maschvilku.cz/auth/callback",
		//redirectUri: "http://localhost:3000/auth/callback",
		navigateToLoginRequestUrl: false
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: true
	}
};

export const authLoginRequest = {
	scopes: [
		"openid",
		"offline_access",
		"https://id.maschvilku.cz/backend/Api.All"
	]
};

export const authScopes = {
	scopes: [
		"https://id.maschvilku.cz/backend/Api.All"
	]
};
