import React from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {AppContext} from "./commons/context/AppContextProvider";
import "./commons/styles/forms.scss";
import Dropzone from 'react-dropzone';
import DefaultLayout from "./commons/layout/DefaultLayout";
import MasChvilkuApiClient from "./api";
import {Redirect} from "react-router-dom";
import axios from "axios";
import _ from "lodash";

interface IState {
	files: Blob[],
	impression: string | null,
	activity: string | null,
	place: string | null,
	satisfaction: number | null,
	feedback: string | null,
	fellowParticipants: string | null,
	withParticipants: boolean | null,
	willRepeat: string | null,
	willRepeatInFreeTime: string | null,
	submitSuccess: boolean,
	submitError: boolean,
	formError: boolean,
	isLoading: boolean,
	redirectUrl: string | null,
}

interface IImageResponse{
	ids: IFileResponse[];
}

interface IFileResponse{
	id: number;
}
export default class AfterActivityForm extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);
		this.state = {
			files: [],
			impression: null,
			activity: null,
			place: null,
			satisfaction: null,
			feedback: null,
			fellowParticipants: null,
			withParticipants: null,
			willRepeat: null,
			willRepeatInFreeTime: null,
			submitSuccess: false,
			submitError: false,
			formError: false,
			isLoading: false,
			redirectUrl: null,
		};

		this.handleSatisfactionInput = this.handleSatisfactionInput.bind(this);
	}

	handleInputChange = (event: any) => {
		const target = event.target;
		if(target.type === 'radio'){
			const parsed = target.name.split("-");
			const name = parsed[0];
			const value = parsed[1] === "yes" ? true : parsed[1] === "no" ? false : parsed[1];

			const newState = { [name]: value } as Pick<IState, keyof IState>;
			this.setState(newState);
		}
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name as string;

		const newState = { [name]: value } as Pick<IState, keyof IState>;
		this.setState(newState);
	}

	handleSatisfactionInput(event: any): any {
		this.setState({ satisfaction: parseInt(event.target.value) });
	}

	handleSubmit = async () => {
		this.setState({submitSuccess: false, submitError: false, isLoading: true, redirectUrl: "", formError: false});

		if (this.state.impression === null
			|| !this.state.activity
			|| !this.state.place
			|| !this.state.satisfaction
			|| (this.state.satisfaction && this.state.satisfaction < 10 && !this.state.feedback)
			|| !this.state.files
			|| this.state.files.length === 0)
		{
			this.setState({isLoading: false, formError: true});
			return;
		}

		const client = await MasChvilkuApiClient.getInstance(this.context, true);


		const form = new FormData();
		this.state.files.forEach(img => {
			form.append("Images", img);
		});

		let fileResult: number[] = [] ;
		try {
			const configuration = await this.context.configuration!.get();
			const result = await axios.post(configuration.api.baseUrl + "/v1/ipsos/images", form);
			const data = result.data as IImageResponse;
			fileResult = data.ids.map(id=> id.id);
		} catch (e) {
			console.error(e);
			this.setState({submitError: true});
			return;
		} finally {
			this.setState({isLoading: false});
		}

		const request = {
			impression: this.state.impression as string,
			activity: this.state.activity as string,
			place: this.state.place as string,
			satisfaction: this.state.satisfaction as number,
			feedback: this.state.feedback as string,
			hasFellowParticipants: this.state.withParticipants as boolean,
			fellowParticipants: this.state.fellowParticipants as string,
			willRepeat: Number(this.state.willRepeat),
			willRepeatInFreeTime: Number(this.state.willRepeatInFreeTime),
			images: fileResult,
		}

		try {
			const result = await client.ipsos.saveActivityQuestionnaire(request);
			const url =  window.location.origin + "/confirmation" + "?id=" + result.data.confirmationId + "&token=" + result.data.confirmationToken;
			this.setState({submitSuccess: true, redirectUrl: url});
		} catch (e) {
			console.error(e);
			this.setState({submitError: true});
		} finally {
			this.setState({isLoading: false});
		}
	}

	handleOnDrop = (files: Blob[]) => {
		this.setState({files:[...this.state.files, ...files]})
	}

	render() {
		if(this.state.submitSuccess){
			return <Redirect push to="/submitSuccess?formType=0"/>
		}

		const scale: number[] = _.range(1, 11);

		return (
			<DefaultLayout>
				<div className="row form-style">
					<div className="mx-auto col-12 col-md-8">
						<h1 className="secondary">Vyplň, prosím, následující dotazník.</h1>
						<span className="form-label">Pole označená * jsou povinná</span>
						<Form className="mt-3">
							<Row className="mb-3">
								<Form.Group controlId="formImpression" >
									<Form.Label>Jakými 5 slovy bys popsal/a své pocity z proběhlého dobrovolnictví?*</Form.Label>
									<Form.Control as="textarea" rows={3} name="impression" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formActivity" >
									<Form.Label>Jakou aktivitu jsi si vybral/a?*</Form.Label>
									<Form.Control as="textarea" rows={3} name="activity" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formPlace" >
									<Form.Label>Kde jsi tuto aktivitu vykonal/a? (organizace, případně místo aktivity)*</Form.Label>
									<Form.Control as="textarea" rows={3} name="place" onChange={this.handleInputChange} />
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formSatisfaction">
									<Form.Label>Jak jsi byl/a celkově spokojen/a se svým dobrovolnickým dnem?*</Form.Label>
									<Row>
										{scale.map(val =>
											<Col lg="1" className="d-none d-lg-block text-center">
												<Form.Label htmlFor={"satisfaction-" + val} className="mb-0">
													{val}
												</Form.Label>
											</Col>
										)}
									</Row>
									<Row>
										{scale.map(val =>
											<Col xs="12" lg="1" className="text-lg-center">
												<Form.Check className="form-check d-inline-block mx-lg-auto p-lg-0">
													<Form.Check.Input
														type="radio"
														name="satisfaction"
														id={"satisfaction-" + val}
														key={"satisfaction-" + val}
														value={val}
														className="m-lg-0 text-lg-center"
														onChange={this.handleSatisfactionInput} />
													<Form.Check.Label
														htmlFor={"satisfaction-" + val}
														className="form-check-label d-lg-none">

														{val === 1 && "1 (zcela nespokojen/a)"}
														{val === 10 && "10 (zcela spokojen/a)"}
														{(val > 1 && val < 10) && val.toString()}
													</Form.Check.Label>
												</Form.Check>
											</Col>
										)}
									</Row>
									<Row className="form-label d-none d-lg-block small">
										<Col>1 = zcela nespokojen/a, 10 = zcela spokojen/a</Col>
									</Row>
								</Form.Group>
							</Row>
							{(this.state.satisfaction && this.state.satisfaction < 10) &&
								<Row className="mb-3">
									<Form.Group controlId="formFeedback" >
										<Form.Label>Jaké změny či vylepšení bys doporučil/a, aby dobrovolnický den maximálně splňoval tvá očekávání?*</Form.Label>
										<Form.Control as="textarea" rows={3} name="feedback" onChange={this.handleInputChange} />
									</Form.Group>
								</Row>
							}
							<Row className="mb-3">
								<Form.Group controlId="formFellowParticipants" >
									<Form.Label>Účastnil se společně s tebou aktivity ještě někdo další (kolega, partner, příbuzný)?</Form.Label>
									<Form.Check
										type="radio"
										label="Ano"
										name="withParticipants-yes"
										id="withParticipants-yes"
										checked={this.state.withParticipants === true}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Ne"
										name="withParticipants-no"
										id="withParticipants-no"
										checked={this.state.withParticipants === false}
										onChange={this.handleInputChange}
									/>
									{this.state.withParticipants === true ?
										<>
											<Form.Label>Kdo se s tebou účastnil (název týmu...)?</Form.Label>
											<Form.Control name="fellowParticipants" onChange={this.handleInputChange} />
										</> : null}
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formWillRepeat" >
									<Form.Label>Měl/a bys zájem se znovu zapojit do dobrovolnictví v rámci firemních aktivit?</Form.Label>
									<Form.Check
										type="radio"
										label="Určitě ano"
										name="willRepeat-4"
										id="willRepeat-4"
										checked={this.state.willRepeat === "4"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše ano"
										name="willRepeat-3"
										id="willRepeat-3"
										checked={this.state.willRepeat === "3"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše ne"
										name="willRepeat-2"
										id="willRepeat-2"
										checked={this.state.willRepeat === "2"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Určitě ne"
										name="willRepeat-1"
										id="willRepeat-1"
										checked={this.state.willRepeat === "1"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Nevím"
										name="willRepeat-0"
										id="willRepeat-0"
										checked={this.state.willRepeat === "0"}
										onChange={this.handleInputChange}
									/>
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formWillRepeatInFreeTime" >
									<Form.Label>Měl/a bys zájem se znovu zapojit do dobrovolnictví soukromě, tedy i mimo firemní aktivity?</Form.Label>
									<Form.Check
										type="radio"
										label="Určitě ano"
										name="willRepeatInFreeTime-4"
										id="willRepeatInFreeTime-4"
										checked={this.state.willRepeatInFreeTime === "4"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše ano"
										name="willRepeatInFreeTime-3"
										id="willRepeatInFreeTime-3"
										checked={this.state.willRepeatInFreeTime === "3"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Spíše ne"
										name="willRepeatInFreeTime-2"
										id="willRepeatInFreeTime-2"
										checked={this.state.willRepeatInFreeTime === "2"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Určitě ne"
										name="willRepeatInFreeTime-1"
										id="willRepeatInFreeTime-1"
										checked={this.state.willRepeatInFreeTime === "1"}
										onChange={this.handleInputChange}
									/>
									<Form.Check
										type="radio"
										label="Nevím"
										name="willRepeatInFreeTime-0"
										id="willRepeatInFreeTime-0"
										checked={this.state.willRepeatInFreeTime === "0"}
										onChange={this.handleInputChange}
									/>
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Group controlId="formGridPhoto" >
									<Form.Label>Máš z dobrovolnické aktivity dobrý pocit? A můžeme ho vidět?*<br/>
										<span className="small">Zde, prosím, nahraj fotky a videa z tvojí dobrovolnické aktivity, které mohou být využity v rámci Ipsos #30DobroTýdnů.<br/>
										V případě, že nemáš fotky nebo je nechceš zveřejnit, nahraj naskenované nebo vyfocené potvrzení o tvé účasti na dobrovolničení.</span>
									</Form.Label>
									<Dropzone onDrop={this.handleOnDrop}>
										{({getRootProps, getInputProps}) => (
											<section>
												<div {...getRootProps()}>
													<input {...getInputProps()} />
													<Row className="dnd mx-1">
														<Col xs={6} className="mx-auto my-auto text-center">Přetáhni soubory nebo klikni pro nahrání</Col>
													</Row>

												</div>
											</section>
										)}
									</Dropzone>
									{this.renderFiles()}
								</Form.Group>
							</Row>
							<Row>
								<Col xs={4} className="mx-auto text-center">
									<Button variant="primary" className="btn btn-mch-secondary btn-mch-rounded mx-auto w-100" onClick={this.handleSubmit} disabled={this.state.isLoading}>
										Uložit
									</Button>
									<div className="mt-3">
										{this.state.isLoading ? <Spinner animation="border" role="status" >
											<span className="visually-hidden">Loading...</span>
										</Spinner> : null}

										{this.state.submitSuccess ? <span className="submit-info">Dotazník uložen.</span> : null}
										{this.state.submitError ? <span className="submit-error">Nepodařilo se odeslat formulář</span> : null}
										{this.state.formError ? <span className="submit-error">Vyplň všechny otázky označené *</span> : null}
									</div>
								</Col>
							</Row>
						</Form>
					</div>
				</div>
			</DefaultLayout>
		)
	}

	renderFiles(){
		if(!this.state.files || this.state.files.length === 0){
			return;
		}

		return (<Row className="mx-1 dnd-files">
			<Col>
				<ul>
					{this.state.files.map((file) => {
						return <li className="link-alike" title="Odebrat" onClick={(e) => {e.preventDefault() ;const newFiles = this.state.files.filter((f) => f !== file ); this.setState({files: newFiles})}}>{(file as any).name}</li>
					})}
				</ul>
			</Col>
		</Row>)
	}
}
