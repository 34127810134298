import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AppContext} from "../commons/context/AppContextProvider";
import MasChvilkuApiClient from "../api";
import {OrganizationResponse} from "../api/client";
import _ from "lodash";

import ITeamMember from "./ITeamMember";
import TeamMembers from "./TeamMembers";

import Footer from "../commons/layout/Footer";

import "./landingpage.scss";

import Background from "../assets/images/background-public.svg";
import LogoIpsos from "../assets/images/logo-ipsos.svg";
import Hero from "../assets/images/hero.png";
import NavBarTop from "../commons/layout/NavBarTop";


interface IState {
	organizations: OrganizationResponse[];
	team: ITeamMember[]
}

export default class PublicLandingPage extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);

		this.state = {
			organizations: [],
			team: TeamMembers
		};
	}

	async componentDidMount() {
		const client = await MasChvilkuApiClient.getInstance(this.context);
		const organizations = await client.organizations.getOrganizations();

		this.setState({ organizations: _.sampleSize(organizations.data, 4) });
	}

	render() {
		return (
			<div id="landing-page" className="landing-page-public">
				<section className="colored">
					<NavBarTop hideLogoOnSmallScreen={true} />
				</section>

				<section id="intro" className="colored">
					<Container>
						<div className="background-container">
							<div className="background">
								<div className="content text-center">
									<img src={LogoIpsos} className="logo" alt="Máš Chvilku" />
								</div>
							</div>

							<img src={Background} alt="background" className="background img-fluid" />
						</div>
					</Container>
				</section>

				<main>
					<section id="how-to">
						<Container>
							<div className="ribbon d-none d-xl-block">
								<div className="ribbon-text">Jak to funguje</div>
							</div>

							<div className="content mx-xl-5">
								<h1 className="color-secondary-dark d-none d-md-block">Dobrý pocit z pomoci je pár kliknutí od tebe.</h1>

								<Row className="mx-1 mx-xl-5 text-center text-md-start">
									<Col xs={12} md={6} xl={3} className="order-xl-1">
										<div className="step top mb-5 mb-md-3">
											<h2>1</h2>
											<h3>Vytvoř si profil</h3>
											<div className="description">
												K registraci stačí e-mail. Na svém profilu budeš moct zobrazit
												přehled svých minulých pomocí, kalendář naplánovaných akcí a budeš si moct
												stáhnout i potvrzení pro zaměstnavatele.
											</div>
										</div>

										<div className="step mb-5 mb-md-0">
											<h2>2</h2>
											<h3>Vyber si činnost</h3>
											<div className="description">
												Pomáhej tam, kde ti to dává nejvíc smysl, kde to máš nejblíž, nebo kde můžeš
												pomáhat pravidelně. Vyplň "Dotazník po registraci", v nabídce aktivit nalezneš organizace zaměřující se na
												pomoc dětem, seniorům, lidem se zdravotním postižením, útulky, organizace
												pro podporu planety a jiné.
											</div>
										</div>
									</Col>

									<Col xs={12} md={6} xl={3} className="order-xl-3">
										<div className="step top mb-5 mb-md-3">
											<h2>3</h2>
											<h3>Zajdi pomoct</h3>
											<div className="description">
												Po zvolení činnosti a termínu zajdi pomoct. Pomáhej ve stanoveném čase. Pokud
												ti do toho na poslední chvíli něco skočí, dej nám včas vědět. Nezapomeň poté vyplnit "Dotazník po aktivitě".
											</div>
										</div>

										<div className="step">
											<h2>4</h2>
											<h3>Užívej si dobrý pocit</h3>
											<div className="description">
												Gratulujeme! Udělal/a jsi něco prospěšného. Teď si právem užívej dobrý pocit
												z dobrého skutku. A zajdi pomoct zase někdy příště!
											</div>
										</div>
									</Col>

									<Col xs={12} xl={6} className="my-auto text-center order-xl-2">
										<div className="art px-5 d-none d-xl-block">
											<img src={Hero} alt="Hrdina" className="img-fluid" />
										</div>

										{/*<div className="mt-5 mt-md-4">*/}
										{/*	<div className="challenge mb-2">Vyrážím pomáhat!</div>*/}

										{/*	<LinkContainer to="/activities">*/}
										{/*		<Button size="lg" className="btn-mch-primary btn-mch-rounded px-5">Registrace</Button>*/}
										{/*	</LinkContainer>*/}
										{/*</div>*/}
									</Col>
								</Row>

								<div className="separator" />

								<div className="ratio ratio-16x9 w-75 mx-auto">
									<iframe className="embed-responsive-item"
											src="https://www.youtube.com/embed/lZJl2yejSCQ"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen />
								</div>
							</div>
						</Container>
					</section>

					<section id="about-us" className="colored">
						<Container>
							<h1>O nás</h1>

							<div>
								<Row className="mx-2 mx-sm-0">
									<Col xs={12} lg={6} className="mb-5 mb-lg-0">
										<h2 className="mb-3">Jak to vzniklo?</h2>

										<p className="lh-lg pe-sm-5">
											Před jedním z odjezdů na návštěvu dalšího dětského domova mi volala Eva ze spolku
											Mimo domov a ptala se mě, zda bych neměl ještě někoho k sobě, protože jí výjezd
											kvůli nemoci odřekli dva členové posádky. Napsal jsem pár přátelům a nakonec
											vzal s sebou vlastní maminku. Ale oslovené přátele to zaujalo a ptali se,
											jestli se mohou přidat v jiném termínu. A tady mě napadlo, jak by se neexpertní
											dobrovolnictví mohlo stát součástí života každého z nás?!

										</p>
										<p className="lh-lg">
											Tato myšlenka se mě držela několik měsíců a dokázal jsem jí formovat, až
											do podoby, kterou jsem představil Kubovi &ndash; jednoduchý on-line vyhledávač
											a rezervační systém dobročinných aktivit pro dobrovolníky!
										</p>
										<p className="lh-lg">
											Kubu myšlenka nadchla, sestavili jsme tým ze svých přátel, kteří rozumí
											oblastem IT, grafiky a financí a pustili se do projektu Máš Chvilku. Projektu,
											který věří, že každý má chvilku, kterou by rád věnoval na pomoc tam, kde není chvilek nazbyt.
										</p>

										<p className="lg-lg">
											<em>&mdash; Honza, člověk s nápadem</em>
										</p>
									</Col>

									<Col xs={12} lg={6}>
										<h2>Kdo za tím stojí?</h2>
										{this.renderTeamMembers()}
									</Col>
								</Row>
							</div>
						</Container>
					</section>
				</main>

				<Footer />
			</div>
		);
	}

	private renderTeamMembers() {
		const rows = _.chunk(this.state.team, 3);
		return rows.map(row => this.renderTeamMembersRow(row));
	}

	private renderTeamMembersRow(teamMembers: ITeamMember[]) {
		return (<Row className="row text-center mb-0 mb-sm-5">{teamMembers.map(this.renderTeamMember)}</Row>);
	}

	private renderTeamMember(teamMember: ITeamMember) {
		return (
			<Col xs={12} sm className="mb-4 mb-sm-0">
				<div className="photo mb-3">
					<img src={teamMember.avatar} alt={teamMember.name} />
				</div>
				<div className="name mb-1">{teamMember.name}</div>
				<div className="bio">{teamMember.bio}</div>
			</Col>
		);
	}
}
