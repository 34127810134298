import {
	ActivityApi,
	ActivityGroupApi,
	ActivityOccurrenceApi,
	Configuration, IpsosApi,
	OrganizationApi
} from "./client";
import IAppContext from "../commons/context/IAppContext";
import AppConfiguration from "../commons/config/models/AppConfiguration";
import axios, {AxiosInstance} from "axios";
import { authScopes } from "../commons/auth/AuthConfiguration";

export default class MasChvilkuApiClient {
	private readonly axiosInstance = axios.create();

	public readonly activities: ActivityApi;
	public readonly organizations: OrganizationApi;
	public readonly activityOccurrence: ActivityOccurrenceApi;
	public readonly activityGroup: ActivityGroupApi;
	public readonly ipsos: IpsosApi;

	private constructor(configuration: AppConfiguration, accessToken?: string) {
		const apiConfiguration = new Configuration({ accessToken });
		if (accessToken) {
			apiConfiguration.baseOptions = {headers: {Authorization: "Bearer " + accessToken}}; //TODO Workaround - this adds access token to every request, otherwise access token is added only to calls to "authorize" endpoint
		}
		const apiParameters: [Configuration, string, AxiosInstance] = [apiConfiguration, configuration.api.baseUrl, this.axiosInstance];

		this.activities = new ActivityApi(...apiParameters);
		this.organizations = new OrganizationApi(...apiParameters);
		this.activityOccurrence = new ActivityOccurrenceApi(...apiParameters);
		this.activityGroup = new ActivityGroupApi(...apiParameters);
		this.ipsos = new IpsosApi(...apiParameters);
	}

	public static async getInstance(appContext: IAppContext, requireAuth: boolean = false): Promise<MasChvilkuApiClient> {
		const configuration = await appContext.configuration!.get();

		if (typeof configuration === 'undefined') {
			throw new Error("Cannot load configuration");
		}

		let accessToken;
		try {
			const tokenResponse = await appContext.authClient!.acquireTokenSilent(authScopes);
			accessToken = tokenResponse?.accessToken ?? undefined;
		} catch (e) {
			accessToken = undefined;
		}

		return new MasChvilkuApiClient(configuration, accessToken);
	}
}
