import React, { Component } from "react";
import IAppContext from "./IAppContext";
import ConfigurationService from "../config/ConfigurationService";
import { PublicClientApplication } from "@azure/msal-browser";
import { authConfiguration } from "../auth/AuthConfiguration";

export const AppContext = React.createContext({} as IAppContext);

export class AppContextProvider extends Component {
	private readonly appContext: IAppContext;

	constructor(props: any) {
		super(props);

		const configurationService = new ConfigurationService();
		const authClient = new PublicClientApplication(authConfiguration);

		this.appContext = {
			configuration: configurationService,
			authClient
		};
	}

	render() {
		return (
			<AppContext.Provider value={this.appContext}>
				{this.props.children}
			</AppContext.Provider>
		);
	}
}
