/* tslint:disable */
/* eslint-disable */
/**
 * MášChvilku.cz API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityFilterCriteria
 */
export interface ActivityFilterCriteria {
    /**
     * 
     * @type {number}
     * @memberof ActivityFilterCriteria
     */
    organizationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityFilterCriteria
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityFilterCriteria
     */
    to?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityGroupResponse
 */
export interface ActivityGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof ActivityGroupResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupResponse
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActivityGroupResponse
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityGroupResponse
     */
    organizationName?: string | null;
}
/**
 * 
 * @export
 * @interface ActivityOccurrenceResponse
 */
export interface ActivityOccurrenceResponse {
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    activityGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityOccurrenceResponse
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityOccurrenceResponse
     */
    startsOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityOccurrenceResponse
     */
    endsOn?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    minCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityOccurrenceResponse
     */
    maxCapacity?: number | null;
    /**
     * 
     * @type {ActivityOccurrenceStatus}
     * @memberof ActivityOccurrenceResponse
     */
    status?: ActivityOccurrenceStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ActivityOccurrenceStatus {
    Open = 'open',
    Finished = 'finished',
    Cancelled = 'cancelled'
}

/**
 * 
 * @export
 * @interface ActivityResponse
 */
export interface ActivityResponse {
    /**
     * 
     * @type {ActivityGroupResponse}
     * @memberof ActivityResponse
     */
    group?: ActivityGroupResponse;
    /**
     * 
     * @type {ActivityOccurrenceResponse}
     * @memberof ActivityResponse
     */
    occurrence?: ActivityOccurrenceResponse;
}
/**
 * 
 * @export
 * @interface ActivityTypeResponse
 */
export interface ActivityTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof ActivityTypeResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityTypeResponse
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface AddOrganizationMemberRequest
 */
export interface AddOrganizationMemberRequest {
    /**
     * 
     * @type {number}
     * @memberof AddOrganizationMemberRequest
     */
    personId: number;
}
/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {number}
     * @memberof AddressResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    line1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    line2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    country?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof AddressResponse
     */
    coordinates?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof AddressResponse
     */
    isPublic?: boolean;
}
/**
 * 
 * @export
 * @interface ArticleCountResponse
 */
export interface ArticleCountResponse {
    /**
     * 
     * @type {number}
     * @memberof ArticleCountResponse
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface ArticleResponse
 */
export interface ArticleResponse {
    /**
     * 
     * @type {number}
     * @memberof ArticleResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleResponse
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleResponse
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleResponse
     */
    modifiedOn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleResponse
     */
    isPinned?: boolean;
    /**
     * 
     * @type {ArticleStatus}
     * @memberof ArticleResponse
     */
    status?: ArticleStatus;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ArticleStatus {
    Draft = 'draft',
    Published = 'published'
}

/**
 * 
 * @export
 * @interface ArticlesResponse
 */
export interface ArticlesResponse {
    /**
     * 
     * @type {Array<ArticleResponse>}
     * @memberof ArticlesResponse
     */
    articles?: Array<ArticleResponse> | null;
}
/**
 * 
 * @export
 * @interface BusinessHoursEntry
 */
export interface BusinessHoursEntry {
    /**
     * 
     * @type {DayOfWeek}
     * @memberof BusinessHoursEntry
     */
    dayOfWeek?: DayOfWeek;
    /**
     * 
     * @type {TimeOnly}
     * @memberof BusinessHoursEntry
     */
    startTime?: TimeOnly;
    /**
     * 
     * @type {TimeOnly}
     * @memberof BusinessHoursEntry
     */
    endTime?: TimeOnly;
}
/**
 * 
 * @export
 * @interface BusinessHoursInfoResponse
 */
export interface BusinessHoursInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof BusinessHoursInfoResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessHoursInfoResponse
     */
    name?: string | null;
}
/**
 * 
 * @export
 * @interface BusinessHoursResponse
 */
export interface BusinessHoursResponse {
    /**
     * 
     * @type {number}
     * @memberof BusinessHoursResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessHoursResponse
     */
    name?: string | null;
    /**
     * 
     * @type {Array<BusinessHoursEntry>}
     * @memberof BusinessHoursResponse
     */
    entries?: Array<BusinessHoursEntry> | null;
}
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    longitude?: number | null;
}
/**
 * 
 * @export
 * @interface CountResponse
 */
export interface CountResponse {
    /**
     * 
     * @type {number}
     * @memberof CountResponse
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface CreateActivityGroupRequest
 */
export interface CreateActivityGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateActivityGroupRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActivityGroupRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateActivityGroupRequest
     */
    organizationId: number;
}
/**
 * 
 * @export
 * @interface CreateActivityGroupResponse
 */
export interface CreateActivityGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateActivityGroupResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateActivityOccurrenceRequest
 */
export interface CreateActivityOccurrenceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateActivityOccurrenceRequest
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateActivityOccurrenceRequest
     */
    startsOn: string;
    /**
     * 
     * @type {string}
     * @memberof CreateActivityOccurrenceRequest
     */
    endsOn: string;
    /**
     * 
     * @type {number}
     * @memberof CreateActivityOccurrenceRequest
     */
    minCapacity: number;
    /**
     * 
     * @type {number}
     * @memberof CreateActivityOccurrenceRequest
     */
    maxCapacity?: number | null;
}
/**
 * 
 * @export
 * @interface CreateActivityOccurrenceResponse
 */
export interface CreateActivityOccurrenceResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateActivityOccurrenceResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateActivityTypeRequest
 */
export interface CreateActivityTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateActivityTypeRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateActivityTypeResponse
 */
export interface CreateActivityTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateActivityTypeResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateAddressResponse
 */
export interface CreateAddressResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateAddressResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateArticleRequest
 */
export interface CreateArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateArticleRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleRequest
     */
    text?: string | null;
    /**
     * 
     * @type {ArticleStatus}
     * @memberof CreateArticleRequest
     */
    status?: ArticleStatus;
}
/**
 * 
 * @export
 * @interface CreateArticleResponse
 */
export interface CreateArticleResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateArticleResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateBusinessHoursRequest
 */
export interface CreateBusinessHoursRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBusinessHoursRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CreateBusinessHoursResponse
 */
export interface CreateBusinessHoursResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateBusinessHoursResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface CreateOrganizationRequest
 */
export interface CreateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    country: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof CreateOrganizationRequest
     */
    coordinates?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationRequest
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface DateTimeResponse
 */
export interface DateTimeResponse {
    /**
     * 
     * @type {string}
     * @memberof DateTimeResponse
     */
    dateTime?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DayOfWeek {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum EmployeeRole {
    Owner = 'owner',
    Member = 'member'
}

/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    filename?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface IpsosActivityQuestionnaireResponse
 */
export interface IpsosActivityQuestionnaireResponse {
    /**
     * 
     * @type {number}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    personId?: number;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    impression?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    activity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    place?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    hasFellowParticipants?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    fellowParticipants?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    willRepeat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    willRepeatInFreeTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    satisfaction?: number | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosActivityQuestionnaireResponse
     */
    feedback?: string | null;
}
/**
 * 
 * @export
 * @interface IpsosConfirmActivityRequest
 */
export interface IpsosConfirmActivityRequest {
    /**
     * 
     * @type {string}
     * @memberof IpsosConfirmActivityRequest
     */
    organizationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosConfirmActivityRequest
     */
    organizationContactPerson?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosConfirmActivityRequest
     */
    organizationContact?: string | null;
}
/**
 * 
 * @export
 * @interface IpsosEmployeeResponse
 */
export interface IpsosEmployeeResponse {
    /**
     * 
     * @type {number}
     * @memberof IpsosEmployeeResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IpsosEmployeeResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosEmployeeResponse
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosEmployeeResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosEmployeeResponse
     */
    department?: string | null;
}
/**
 * 
 * @export
 * @interface IpsosImageResponse
 */
export interface IpsosImageResponse {
    /**
     * 
     * @type {number}
     * @memberof IpsosImageResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IpsosImageResponse
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosImageResponse
     */
    blobStorageLink?: string | null;
}
/**
 * 
 * @export
 * @interface IpsosIntroductionQuestionnaireResponse
 */
export interface IpsosIntroductionQuestionnaireResponse {
    /**
     * 
     * @type {number}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    personId?: number;
    /**
     * 
     * @type {string}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    createdOn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    customActivity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    customOrganization?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferNature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferAnimals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferChildren?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferElder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferEmergency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    excludeNature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    excludeAnimals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    excludeChildren?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    excludeElder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    excludeEmergency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    individual?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    accompanion?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    preferedWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof IpsosIntroductionQuestionnaireResponse
     */
    benefitOpinionRank?: number;
}
/**
 * 
 * @export
 * @interface IpsosRegistrationRequest
 */
export interface IpsosRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationRequest
     */
    department: string;
}
/**
 * 
 * @export
 * @interface IpsosRegistrationResponse
 */
export interface IpsosRegistrationResponse {
    /**
     * 
     * @type {string}
     * @memberof IpsosRegistrationResponse
     */
    email?: string | null;
}
/**
 * 
 * @export
 * @interface IsAdminResponse
 */
export interface IsAdminResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IsAdminResponse
     */
    isAdmin?: boolean;
}
/**
 * 
 * @export
 * @interface MessageCountResponse
 */
export interface MessageCountResponse {
    /**
     * 
     * @type {number}
     * @memberof MessageCountResponse
     */
    count?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MessageDeliveryStatus {
    Unknown = 'unknown',
    Received = 'received',
    Read = 'read'
}

/**
 * 
 * @export
 * @interface MessageParty
 */
export interface MessageParty {
    /**
     * 
     * @type {MessagePartyType}
     * @memberof MessageParty
     */
    type?: MessagePartyType;
    /**
     * 
     * @type {number}
     * @memberof MessageParty
     */
    id?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum MessagePartyType {
    Person = 'person',
    Organization = 'organization',
    Group = 'group'
}

/**
 * 
 * @export
 * @interface MessagePreviewResponse
 */
export interface MessagePreviewResponse {
    /**
     * 
     * @type {number}
     * @memberof MessagePreviewResponse
     */
    senderId?: number;
    /**
     * 
     * @type {number}
     * @memberof MessagePreviewResponse
     */
    senderOrganizationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessagePreviewResponse
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessagePreviewResponse
     */
    sentOn?: string;
    /**
     * 
     * @type {MessageDeliveryStatus}
     * @memberof MessagePreviewResponse
     */
    status?: MessageDeliveryStatus;
}
/**
 * 
 * @export
 * @interface MessageResponse
 */
export interface MessageResponse {
    /**
     * 
     * @type {number}
     * @memberof MessageResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageResponse
     */
    senderId?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageResponse
     */
    senderOrganizationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    subjectContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageResponse
     */
    sentOn?: string;
    /**
     * 
     * @type {MessageDeliveryStatus}
     * @memberof MessageResponse
     */
    status?: MessageDeliveryStatus;
}
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationResponse
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationResponse
     */
    recipientId?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    sentOn?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationResponse
     */
    readOn?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationResponse
     */
    isArchived?: boolean;
}
/**
 * 
 * @export
 * @interface OrganizationCountResponse
 */
export interface OrganizationCountResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationCountResponse
     */
    count?: number;
}
/**
 * 
 * @export
 * @interface OrganizationMemberResponse
 */
export interface OrganizationMemberResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationMemberResponse
     */
    personId?: number;
    /**
     * 
     * @type {EmployeeRole}
     * @memberof OrganizationMemberResponse
     */
    role?: EmployeeRole;
}
/**
 * 
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationResponse
     */
    defaultAddressId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationResponse
     */
    phoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface PersonResponse
 */
export interface PersonResponse {
    /**
     * 
     * @type {string}
     * @memberof PersonResponse
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonResponse
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonResponse
     */
    bio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonResponse
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonResponse
     */
    phoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface SaveIpsosActivityQuestionnaireRequest
 */
export interface SaveIpsosActivityQuestionnaireRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    impression: string;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    activity: string;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    place: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    hasFellowParticipants?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    fellowParticipants?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    willRepeat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    willRepeatInFreeTime?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    colleageTalk?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    colleageTalkTopic?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    satisfaction?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    feedback?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveIpsosActivityQuestionnaireRequest
     */
    images?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SaveIpsosActivityQuestionnaireResponse
 */
export interface SaveIpsosActivityQuestionnaireResponse {
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosActivityQuestionnaireResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireResponse
     */
    confirmationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosActivityQuestionnaireResponse
     */
    confirmationToken?: string | null;
}
/**
 * 
 * @export
 * @interface SaveIpsosIntroductionQuestionnaireRequest
 */
export interface SaveIpsosIntroductionQuestionnaireRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    customActivity?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    customOrganization?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferNature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferAnimals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferChildren?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferElder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferEmergency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    excludeNature?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    excludeAnimals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    excludeChildren?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    excludeElder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    excludeEmergency?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    individual?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    accompanion?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    preferedWeek?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    benefitOpinionRank?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SaveIpsosIntroductionQuestionnaireRequest
     */
    socialResponsibility?: boolean | null;
}
/**
 * 
 * @export
 * @interface SaveIpsosIntroductionQuestionnaireResponse
 */
export interface SaveIpsosIntroductionQuestionnaireResponse {
    /**
     * 
     * @type {number}
     * @memberof SaveIpsosIntroductionQuestionnaireResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface SendMessageRequest
 */
export interface SendMessageRequest {
    /**
     * 
     * @type {number}
     * @memberof SendMessageRequest
     */
    threadId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SendMessageRequest
     */
    senderOrganizationId?: number | null;
    /**
     * 
     * @type {Array<MessageParty>}
     * @memberof SendMessageRequest
     */
    recipients: Array<MessageParty>;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @interface SendMessageResponse
 */
export interface SendMessageResponse {
    /**
     * 
     * @type {number}
     * @memberof SendMessageResponse
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface SetOrganizationMemberRoleRequest
 */
export interface SetOrganizationMemberRoleRequest {
    /**
     * 
     * @type {EmployeeRole}
     * @memberof SetOrganizationMemberRoleRequest
     */
    role: EmployeeRole;
}
/**
 * 
 * @export
 * @interface SuggestedAddressDetailsResponse
 */
export interface SuggestedAddressDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressDetailsResponse
     */
    line1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressDetailsResponse
     */
    line2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressDetailsResponse
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressDetailsResponse
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressDetailsResponse
     */
    countryCode?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof SuggestedAddressDetailsResponse
     */
    coordinates?: Coordinates;
}
/**
 * 
 * @export
 * @interface SuggestedAddressResponse
 */
export interface SuggestedAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressResponse
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestedAddressResponse
     */
    address?: string | null;
}
/**
 * 
 * @export
 * @interface ThreadPreviewResponse
 */
export interface ThreadPreviewResponse {
    /**
     * 
     * @type {number}
     * @memberof ThreadPreviewResponse
     */
    id?: number;
    /**
     * 
     * @type {MessagePreviewResponse}
     * @memberof ThreadPreviewResponse
     */
    lastMessage?: MessagePreviewResponse;
}
/**
 * 
 * @export
 * @interface ThreadResponse
 */
export interface ThreadResponse {
    /**
     * 
     * @type {number}
     * @memberof ThreadResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ThreadResponse
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MessageResponse>}
     * @memberof ThreadResponse
     */
    messages?: Array<MessageResponse> | null;
}
/**
 * 
 * @export
 * @interface TimeOnly
 */
export interface TimeOnly {
    /**
     * 
     * @type {number}
     * @memberof TimeOnly
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeOnly
     */
    minute?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeOnly
     */
    second?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeOnly
     */
    millisecond?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeOnly
     */
    ticks?: number;
}
/**
 * 
 * @export
 * @interface UpdateActivityGroupRequest
 */
export interface UpdateActivityGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityGroupRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityGroupRequest
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateActivityOccurrenceRequest
 */
export interface UpdateActivityOccurrenceRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityOccurrenceRequest
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityOccurrenceRequest
     */
    startsOn: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityOccurrenceRequest
     */
    endsOn: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateActivityOccurrenceRequest
     */
    minCapacity: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateActivityOccurrenceRequest
     */
    maxCapacity?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateActivityTypeRequest
 */
export interface UpdateActivityTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateActivityTypeRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UpdateArticleRequest
 */
export interface UpdateArticleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleRequest
     */
    text?: string | null;
    /**
     * 
     * @type {ArticleStatus}
     * @memberof UpdateArticleRequest
     */
    status?: ArticleStatus;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationNameRequest
 */
export interface UpdateOrganizationNameRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationNameRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationRequest
 */
export interface UpdateOrganizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationRequest
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface UploadImageResponse
 */
export interface UploadImageResponse {
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof UploadImageResponse
     */
    ids?: Array<FileResponse> | null;
}
/**
 * 
 * @export
 * @interface UpsertAddressRequest
 */
export interface UpsertAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    line1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    line2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertAddressRequest
     */
    country?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof UpsertAddressRequest
     */
    coordinates?: Coordinates;
}
/**
 * 
 * @export
 * @interface VersionResponse
 */
export interface VersionResponse {
    /**
     * 
     * @type {string}
     * @memberof VersionResponse
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionResponse
     */
    build?: string | null;
}

/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [myActivities] 
         * @param {ActivityFilterCriteria} [activityFilterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities: async (myActivities?: boolean, activityFilterCriteria?: ActivityFilterCriteria, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (myActivities !== undefined) {
                localVarQueryParameter['myActivities'] = myActivities;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityFilterCriteria, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [myActivities] 
         * @param {ActivityFilterCriteria} [activityFilterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivities(myActivities?: boolean, activityFilterCriteria?: ActivityFilterCriteria, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivities(myActivities, activityFilterCriteria, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [myActivities] 
         * @param {ActivityFilterCriteria} [activityFilterCriteria] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivities(myActivities?: boolean, activityFilterCriteria?: ActivityFilterCriteria, options?: any): AxiosPromise<Array<ActivityResponse>> {
            return localVarFp.getActivities(myActivities, activityFilterCriteria, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [myActivities] 
     * @param {ActivityFilterCriteria} [activityFilterCriteria] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public getActivities(myActivities?: boolean, activityFilterCriteria?: ActivityFilterCriteria, options?: any) {
        return ActivityApiFp(this.configuration).getActivities(myActivities, activityFilterCriteria, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityGroupApi - axios parameter creator
 * @export
 */
export const ActivityGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateActivityGroupRequest} [createActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityGroup: async (createActivityGroupRequest?: CreateActivityGroupRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivityGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityGroups: async (organizationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityGroupRequest} [updateActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityGroup: async (id: number, updateActivityGroupRequest?: UpdateActivityGroupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityGroup', 'id', id)
            const localVarPath = `/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActivityGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityGroupApi - functional programming interface
 * @export
 */
export const ActivityGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateActivityGroupRequest} [createActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivityGroup(createActivityGroupRequest?: CreateActivityGroupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivityGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityGroup(createActivityGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityGroups(organizationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityGroupResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityGroups(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityGroupRequest} [updateActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityGroup(id: number, updateActivityGroupRequest?: UpdateActivityGroupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityGroup(id, updateActivityGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityGroupApi - factory interface
 * @export
 */
export const ActivityGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityGroupApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateActivityGroupRequest} [createActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityGroup(createActivityGroupRequest?: CreateActivityGroupRequest, options?: any): AxiosPromise<CreateActivityGroupResponse> {
            return localVarFp.createActivityGroup(createActivityGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [organizationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityGroups(organizationId?: number, options?: any): AxiosPromise<Array<ActivityGroupResponse>> {
            return localVarFp.getActivityGroups(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityGroupRequest} [updateActivityGroupRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityGroup(id: number, updateActivityGroupRequest?: UpdateActivityGroupRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivityGroup(id, updateActivityGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityGroupApi - object-oriented interface
 * @export
 * @class ActivityGroupApi
 * @extends {BaseAPI}
 */
export class ActivityGroupApi extends BaseAPI {
    /**
     * 
     * @param {CreateActivityGroupRequest} [createActivityGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public createActivityGroup(createActivityGroupRequest?: CreateActivityGroupRequest, options?: any) {
        return ActivityGroupApiFp(this.configuration).createActivityGroup(createActivityGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [organizationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public getActivityGroups(organizationId?: number, options?: any) {
        return ActivityGroupApiFp(this.configuration).getActivityGroups(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateActivityGroupRequest} [updateActivityGroupRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityGroupApi
     */
    public updateActivityGroup(id: number, updateActivityGroupRequest?: UpdateActivityGroupRequest, options?: any) {
        return ActivityGroupApiFp(this.configuration).updateActivityGroup(id, updateActivityGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityOccurrenceApi - axios parameter creator
 * @export
 */
export const ActivityOccurrenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} activityGroupId 
         * @param {CreateActivityOccurrenceRequest} [createActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityOccurrence: async (activityGroupId: number, createActivityOccurrenceRequest?: CreateActivityOccurrenceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityGroupId' is not null or undefined
            assertParamExists('createActivityOccurrence', 'activityGroupId', activityGroupId)
            const localVarPath = `/v1/occurrences`
                .replace(`{${"activityGroupId"}}`, encodeURIComponent(String(activityGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivityOccurrenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityOccurrence: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityOccurrence', 'id', id)
            const localVarPath = `/v1/occurrences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} activityOccurrence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendance: async (activityOccurrence: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityOccurrence' is not null or undefined
            assertParamExists('getAttendance', 'activityOccurrence', activityOccurrence)
            const localVarPath = `/v1/occurrences/{activityOccurrence}/attendance`
                .replace(`{${"activityOccurrence"}}`, encodeURIComponent(String(activityOccurrence)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinActivityOccurrence: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('joinActivityOccurrence', 'id', id)
            const localVarPath = `/v1/occurrences/{id}/join`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} activityOccurrenceId 
         * @param {UpdateActivityOccurrenceRequest} [updateActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityOccurrence: async (activityOccurrenceId: number, updateActivityOccurrenceRequest?: UpdateActivityOccurrenceRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityOccurrenceId' is not null or undefined
            assertParamExists('updateActivityOccurrence', 'activityOccurrenceId', activityOccurrenceId)
            const localVarPath = `/v1/occurrences/{activityOccurrenceId}`
                .replace(`{${"activityOccurrenceId"}}`, encodeURIComponent(String(activityOccurrenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActivityOccurrenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityOccurrenceApi - functional programming interface
 * @export
 */
export const ActivityOccurrenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityOccurrenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} activityGroupId 
         * @param {CreateActivityOccurrenceRequest} [createActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivityOccurrence(activityGroupId: number, createActivityOccurrenceRequest?: CreateActivityOccurrenceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivityOccurrenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityOccurrence(activityGroupId, createActivityOccurrenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityOccurrence(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityOccurrence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} activityOccurrence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttendance(activityOccurrence: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttendance(activityOccurrence, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinActivityOccurrence(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinActivityOccurrence(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} activityOccurrenceId 
         * @param {UpdateActivityOccurrenceRequest} [updateActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityOccurrence(activityOccurrenceId: number, updateActivityOccurrenceRequest?: UpdateActivityOccurrenceRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityOccurrence(activityOccurrenceId, updateActivityOccurrenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityOccurrenceApi - factory interface
 * @export
 */
export const ActivityOccurrenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityOccurrenceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} activityGroupId 
         * @param {CreateActivityOccurrenceRequest} [createActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityOccurrence(activityGroupId: number, createActivityOccurrenceRequest?: CreateActivityOccurrenceRequest, options?: any): AxiosPromise<CreateActivityOccurrenceResponse> {
            return localVarFp.createActivityOccurrence(activityGroupId, createActivityOccurrenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityOccurrence(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getActivityOccurrence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} activityOccurrence 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttendance(activityOccurrence: number, options?: any): AxiosPromise<Array<PersonResponse>> {
            return localVarFp.getAttendance(activityOccurrence, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinActivityOccurrence(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.joinActivityOccurrence(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} activityOccurrenceId 
         * @param {UpdateActivityOccurrenceRequest} [updateActivityOccurrenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityOccurrence(activityOccurrenceId: number, updateActivityOccurrenceRequest?: UpdateActivityOccurrenceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivityOccurrence(activityOccurrenceId, updateActivityOccurrenceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityOccurrenceApi - object-oriented interface
 * @export
 * @class ActivityOccurrenceApi
 * @extends {BaseAPI}
 */
export class ActivityOccurrenceApi extends BaseAPI {
    /**
     * 
     * @param {number} activityGroupId 
     * @param {CreateActivityOccurrenceRequest} [createActivityOccurrenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityOccurrenceApi
     */
    public createActivityOccurrence(activityGroupId: number, createActivityOccurrenceRequest?: CreateActivityOccurrenceRequest, options?: any) {
        return ActivityOccurrenceApiFp(this.configuration).createActivityOccurrence(activityGroupId, createActivityOccurrenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityOccurrenceApi
     */
    public getActivityOccurrence(id: number, options?: any) {
        return ActivityOccurrenceApiFp(this.configuration).getActivityOccurrence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} activityOccurrence 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityOccurrenceApi
     */
    public getAttendance(activityOccurrence: number, options?: any) {
        return ActivityOccurrenceApiFp(this.configuration).getAttendance(activityOccurrence, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityOccurrenceApi
     */
    public joinActivityOccurrence(id: number, options?: any) {
        return ActivityOccurrenceApiFp(this.configuration).joinActivityOccurrence(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} activityOccurrenceId 
     * @param {UpdateActivityOccurrenceRequest} [updateActivityOccurrenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityOccurrenceApi
     */
    public updateActivityOccurrence(activityOccurrenceId: number, updateActivityOccurrenceRequest?: UpdateActivityOccurrenceRequest, options?: any) {
        return ActivityOccurrenceApiFp(this.configuration).updateActivityOccurrence(activityOccurrenceId, updateActivityOccurrenceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityTypeApi - axios parameter creator
 * @export
 */
export const ActivityTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveActivityType: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveActivityType', 'id', id)
            const localVarPath = `/v1/activity-types/{id}/approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityType: async (createActivityTypeRequest?: CreateActivityTypeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/activity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivityTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityType: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteActivityType', 'id', id)
            const localVarPath = `/v1/activity-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/activity-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedActivityTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/activity-types/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeActivityType: async (createActivityTypeRequest?: CreateActivityTypeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/activity-types/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createActivityTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityTypeRequest} [updateActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityType: async (id: number, updateActivityTypeRequest?: UpdateActivityTypeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivityType', 'id', id)
            const localVarPath = `/v1/activity-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActivityTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityTypeApi - functional programming interface
 * @export
 */
export const ActivityTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveActivityType(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveActivityType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivityTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActivityType(createActivityTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivityType(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivityType(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposedActivityTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposedActivityTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateActivityTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeActivityType(createActivityTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityTypeRequest} [updateActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivityType(id: number, updateActivityTypeRequest?: UpdateActivityTypeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivityType(id, updateActivityTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityTypeApi - factory interface
 * @export
 */
export const ActivityTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveActivityType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.approveActivityType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any): AxiosPromise<CreateActivityTypeResponse> {
            return localVarFp.createActivityType(createActivityTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivityType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActivityType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityTypes(options?: any): AxiosPromise<Array<ActivityTypeResponse>> {
            return localVarFp.getActivityTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposedActivityTypes(options?: any): AxiosPromise<Array<ActivityTypeResponse>> {
            return localVarFp.getProposedActivityTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any): AxiosPromise<CreateActivityTypeResponse> {
            return localVarFp.proposeActivityType(createActivityTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateActivityTypeRequest} [updateActivityTypeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivityType(id: number, updateActivityTypeRequest?: UpdateActivityTypeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivityType(id, updateActivityTypeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityTypeApi - object-oriented interface
 * @export
 * @class ActivityTypeApi
 * @extends {BaseAPI}
 */
export class ActivityTypeApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public approveActivityType(id: number, options?: any) {
        return ActivityTypeApiFp(this.configuration).approveActivityType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public createActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any) {
        return ActivityTypeApiFp(this.configuration).createActivityType(createActivityTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public deleteActivityType(id: number, options?: any) {
        return ActivityTypeApiFp(this.configuration).deleteActivityType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public getActivityTypes(options?: any) {
        return ActivityTypeApiFp(this.configuration).getActivityTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public getProposedActivityTypes(options?: any) {
        return ActivityTypeApiFp(this.configuration).getProposedActivityTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateActivityTypeRequest} [createActivityTypeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public proposeActivityType(createActivityTypeRequest?: CreateActivityTypeRequest, options?: any) {
        return ActivityTypeApiFp(this.configuration).proposeActivityType(createActivityTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateActivityTypeRequest} [updateActivityTypeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTypeApi
     */
    public updateActivityType(id: number, updateActivityTypeRequest?: UpdateActivityTypeRequest, options?: any) {
        return ActivityTypeApiFp(this.configuration).updateActivityType(id, updateActivityTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestion: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getAddressSuggestion', 'key', key)
            const localVarPath = `/v1/addresses/suggestions/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestions: async (input?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/addresses/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (input !== undefined) {
                localVarQueryParameter['input'] = input;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAddress: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPublicAddress', 'id', id)
            const localVarPath = `/v1/addresses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressSuggestion(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestedAddressDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressSuggestion(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressSuggestions(input?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuggestedAddressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressSuggestions(input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicAddress(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicAddress(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressApiFp(configuration)
    return {
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestion(key: string, options?: any): AxiosPromise<SuggestedAddressDetailsResponse> {
            return localVarFp.getAddressSuggestion(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressSuggestions(input?: string, options?: any): AxiosPromise<Array<SuggestedAddressResponse>> {
            return localVarFp.getAddressSuggestions(input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAddress(id: number, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.getPublicAddress(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI {
    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddressSuggestion(key: string, options?: any) {
        return AddressApiFp(this.configuration).getAddressSuggestion(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getAddressSuggestions(input?: string, options?: any) {
        return AddressApiFp(this.configuration).getAddressSuggestions(input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressApi
     */
    public getPublicAddress(id: number, options?: any) {
        return AddressApiFp(this.configuration).getPublicAddress(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateArticleRequest} [createArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle: async (createArticleRequest?: CreateArticleRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getArticle', 'id', id)
            const localVarPath = `/v1/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/articles/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ArticleStatus} [status] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticles: async (status?: ArticleStatus, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinArticle: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pinArticle', 'id', id)
            const localVarPath = `/v1/articles/{id}/pin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinArticle: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unpinArticle', 'id', id)
            const localVarPath = `/v1/articles/{id}/pin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateArticleRequest} [updateArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle: async (id: number, updateArticleRequest?: UpdateArticleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateArticle', 'id', id)
            const localVarPath = `/v1/articles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateArticleRequest} [createArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArticle(createArticleRequest?: CreateArticleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArticle(createArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticle(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ArticleStatus} [status] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticles(status?: ArticleStatus, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticlesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticles(status, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pinArticle(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pinArticle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpinArticle(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpinArticle(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateArticleRequest} [updateArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticle(id: number, updateArticleRequest?: UpdateArticleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticle(id, updateArticleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateArticleRequest} [createArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticle(createArticleRequest?: CreateArticleRequest, options?: any): AxiosPromise<CreateArticleResponse> {
            return localVarFp.createArticle(createArticleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticle(id: number, options?: any): AxiosPromise<ArticleResponse> {
            return localVarFp.getArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleCount(options?: any): AxiosPromise<ArticleCountResponse> {
            return localVarFp.getArticleCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ArticleStatus} [status] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticles(status?: ArticleStatus, page?: number, options?: any): AxiosPromise<ArticlesResponse> {
            return localVarFp.getArticles(status, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pinArticle(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.pinArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpinArticle(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unpinArticle(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateArticleRequest} [updateArticleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticle(id: number, updateArticleRequest?: UpdateArticleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateArticle(id, updateArticleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {CreateArticleRequest} [createArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public createArticle(createArticleRequest?: CreateArticleRequest, options?: any) {
        return ArticleApiFp(this.configuration).createArticle(createArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticle(id: number, options?: any) {
        return ArticleApiFp(this.configuration).getArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticleCount(options?: any) {
        return ArticleApiFp(this.configuration).getArticleCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ArticleStatus} [status] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticles(status?: ArticleStatus, page?: number, options?: any) {
        return ArticleApiFp(this.configuration).getArticles(status, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public pinArticle(id: number, options?: any) {
        return ArticleApiFp(this.configuration).pinArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public unpinArticle(id: number, options?: any) {
        return ArticleApiFp(this.configuration).unpinArticle(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateArticleRequest} [updateArticleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public updateArticle(id: number, updateArticleRequest?: UpdateArticleRequest, options?: any) {
        return ArticleApiFp(this.configuration).updateArticle(id, updateArticleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessHoursApi - axios parameter creator
 * @export
 */
export const BusinessHoursApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {CreateBusinessHoursRequest} [createBusinessHoursRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationBusinessHours: async (organizationId: number, createBusinessHoursRequest?: CreateBusinessHoursRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createOrganizationBusinessHours', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/business-hours`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBusinessHoursRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBusinessHours: async (organizationId: number, businessHoursId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationBusinessHours', 'organizationId', organizationId)
            // verify required parameter 'businessHoursId' is not null or undefined
            assertParamExists('getOrganizationBusinessHours', 'businessHoursId', businessHoursId)
            const localVarPath = `/v1/organizations/{organizationId}/business-hours/{businessHoursId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"businessHoursId"}}`, encodeURIComponent(String(businessHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBusinessHoursAll: async (organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationBusinessHoursAll', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/business-hours`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {Array<BusinessHoursEntry>} [businessHoursEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationBusinessHoursEntries: async (organizationId: number, businessHoursId: number, businessHoursEntry?: Array<BusinessHoursEntry>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('setOrganizationBusinessHoursEntries', 'organizationId', organizationId)
            // verify required parameter 'businessHoursId' is not null or undefined
            assertParamExists('setOrganizationBusinessHoursEntries', 'businessHoursId', businessHoursId)
            const localVarPath = `/v1/organizations/{organizationId}/business-hours/{businessHoursId}/entries`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"businessHoursId"}}`, encodeURIComponent(String(businessHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessHoursEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessHoursApi - functional programming interface
 * @export
 */
export const BusinessHoursApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessHoursApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {CreateBusinessHoursRequest} [createBusinessHoursRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationBusinessHours(organizationId: number, createBusinessHoursRequest?: CreateBusinessHoursRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBusinessHoursResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationBusinessHours(organizationId, createBusinessHoursRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationBusinessHours(organizationId: number, businessHoursId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessHoursResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationBusinessHours(organizationId, businessHoursId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationBusinessHoursAll(organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessHoursInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationBusinessHoursAll(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {Array<BusinessHoursEntry>} [businessHoursEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrganizationBusinessHoursEntries(organizationId: number, businessHoursId: number, businessHoursEntry?: Array<BusinessHoursEntry>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrganizationBusinessHoursEntries(organizationId, businessHoursId, businessHoursEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessHoursApi - factory interface
 * @export
 */
export const BusinessHoursApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessHoursApiFp(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {CreateBusinessHoursRequest} [createBusinessHoursRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationBusinessHours(organizationId: number, createBusinessHoursRequest?: CreateBusinessHoursRequest, options?: any): AxiosPromise<CreateBusinessHoursResponse> {
            return localVarFp.createOrganizationBusinessHours(organizationId, createBusinessHoursRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBusinessHours(organizationId: number, businessHoursId: number, options?: any): AxiosPromise<BusinessHoursResponse> {
            return localVarFp.getOrganizationBusinessHours(organizationId, businessHoursId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationBusinessHoursAll(organizationId: number, options?: any): AxiosPromise<Array<BusinessHoursInfoResponse>> {
            return localVarFp.getOrganizationBusinessHoursAll(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} businessHoursId 
         * @param {Array<BusinessHoursEntry>} [businessHoursEntry] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationBusinessHoursEntries(organizationId: number, businessHoursId: number, businessHoursEntry?: Array<BusinessHoursEntry>, options?: any): AxiosPromise<void> {
            return localVarFp.setOrganizationBusinessHoursEntries(organizationId, businessHoursId, businessHoursEntry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessHoursApi - object-oriented interface
 * @export
 * @class BusinessHoursApi
 * @extends {BaseAPI}
 */
export class BusinessHoursApi extends BaseAPI {
    /**
     * 
     * @param {number} organizationId 
     * @param {CreateBusinessHoursRequest} [createBusinessHoursRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessHoursApi
     */
    public createOrganizationBusinessHours(organizationId: number, createBusinessHoursRequest?: CreateBusinessHoursRequest, options?: any) {
        return BusinessHoursApiFp(this.configuration).createOrganizationBusinessHours(organizationId, createBusinessHoursRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} businessHoursId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessHoursApi
     */
    public getOrganizationBusinessHours(organizationId: number, businessHoursId: number, options?: any) {
        return BusinessHoursApiFp(this.configuration).getOrganizationBusinessHours(organizationId, businessHoursId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessHoursApi
     */
    public getOrganizationBusinessHoursAll(organizationId: number, options?: any) {
        return BusinessHoursApiFp(this.configuration).getOrganizationBusinessHoursAll(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} businessHoursId 
     * @param {Array<BusinessHoursEntry>} [businessHoursEntry] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessHoursApi
     */
    public setOrganizationBusinessHoursEntries(organizationId: number, businessHoursId: number, businessHoursEntry?: Array<BusinessHoursEntry>, options?: any) {
        return BusinessHoursApiFp(this.configuration).setOrganizationBusinessHoursEntries(organizationId, businessHoursId, businessHoursEntry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IpsosApi - axios parameter creator
 * @export
 */
export const IpsosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} confirmationId 
         * @param {string} [confirmationToken] 
         * @param {IpsosConfirmActivityRequest} [ipsosConfirmActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActivity: async (confirmationId: string, confirmationToken?: string, ipsosConfirmActivityRequest?: IpsosConfirmActivityRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('confirmActivity', 'confirmationId', confirmationId)
            const localVarPath = `/v1/ipsos/questionnaires/activity/{confirmationId}/confirm`
                .replace(`{${"confirmationId"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (confirmationToken !== undefined) {
                localVarQueryParameter['confirmationToken'] = confirmationToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ipsosConfirmActivityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countActivityQuestionnaires: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/activity/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countIntroductionQuestionnaires: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/introduction/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityImage: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityImage', 'id', id)
            const localVarPath = `/v1/ipsos/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityImages: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityImages', 'id', id)
            const localVarPath = `/v1/ipsos/questionnaires/activity/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityQuestionnaires: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntroductionQuestionnaires: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/introduction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAnsweredIntroductionQuestionnaire: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/introduction/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAdmin: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/users/me/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IpsosRegistrationRequest} [ipsosRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (ipsosRegistrationRequest?: IpsosRegistrationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ipsosRegistrationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveIpsosActivityQuestionnaireRequest} [saveIpsosActivityQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityQuestionnaire: async (saveIpsosActivityQuestionnaireRequest?: SaveIpsosActivityQuestionnaireRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveIpsosActivityQuestionnaireRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveIpsosIntroductionQuestionnaireRequest} [saveIpsosIntroductionQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIntroductionQuestionnaire: async (saveIpsosIntroductionQuestionnaireRequest?: SaveIpsosIntroductionQuestionnaireRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/questionnaires/introduction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveIpsosIntroductionQuestionnaireRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage: async (images?: Array<any>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ipsos/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('Images', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IpsosApi - functional programming interface
 * @export
 */
export const IpsosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IpsosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} confirmationId 
         * @param {string} [confirmationToken] 
         * @param {IpsosConfirmActivityRequest} [ipsosConfirmActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmActivity(confirmationId: string, confirmationToken?: string, ipsosConfirmActivityRequest?: IpsosConfirmActivityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmActivity(confirmationId, confirmationToken, ipsosConfirmActivityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countActivityQuestionnaires(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countActivityQuestionnaires(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countIntroductionQuestionnaires(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countIntroductionQuestionnaires(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityImage(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityImages(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IpsosImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityImages(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityQuestionnaires(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IpsosActivityQuestionnaireResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityQuestionnaires(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntroductionQuestionnaires(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IpsosIntroductionQuestionnaireResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntroductionQuestionnaires(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IpsosEmployeeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasAnsweredIntroductionQuestionnaire(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasAnsweredIntroductionQuestionnaire(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isAdmin(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsAdminResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isAdmin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IpsosRegistrationRequest} [ipsosRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(ipsosRegistrationRequest?: IpsosRegistrationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IpsosRegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(ipsosRegistrationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveIpsosActivityQuestionnaireRequest} [saveIpsosActivityQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest?: SaveIpsosActivityQuestionnaireRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveIpsosActivityQuestionnaireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveIpsosIntroductionQuestionnaireRequest} [saveIpsosIntroductionQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest?: SaveIpsosIntroductionQuestionnaireRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveIpsosIntroductionQuestionnaireResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadImage(images?: Array<any>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImage(images, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IpsosApi - factory interface
 * @export
 */
export const IpsosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IpsosApiFp(configuration)
    return {
        /**
         * 
         * @param {string} confirmationId 
         * @param {string} [confirmationToken] 
         * @param {IpsosConfirmActivityRequest} [ipsosConfirmActivityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmActivity(confirmationId: string, confirmationToken?: string, ipsosConfirmActivityRequest?: IpsosConfirmActivityRequest, options?: any): AxiosPromise<void> {
            return localVarFp.confirmActivity(confirmationId, confirmationToken, ipsosConfirmActivityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countActivityQuestionnaires(options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countActivityQuestionnaires(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countIntroductionQuestionnaires(options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countIntroductionQuestionnaires(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUsers(options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityImage(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getActivityImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityImages(id: number, options?: any): AxiosPromise<Array<IpsosImageResponse>> {
            return localVarFp.getActivityImages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityQuestionnaires(options?: any): AxiosPromise<Array<IpsosActivityQuestionnaireResponse>> {
            return localVarFp.getActivityQuestionnaires(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntroductionQuestionnaires(options?: any): AxiosPromise<Array<IpsosIntroductionQuestionnaireResponse>> {
            return localVarFp.getIntroductionQuestionnaires(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<IpsosEmployeeResponse>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasAnsweredIntroductionQuestionnaire(options?: any): AxiosPromise<boolean> {
            return localVarFp.hasAnsweredIntroductionQuestionnaire(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAdmin(options?: any): AxiosPromise<IsAdminResponse> {
            return localVarFp.isAdmin(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IpsosRegistrationRequest} [ipsosRegistrationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(ipsosRegistrationRequest?: IpsosRegistrationRequest, options?: any): AxiosPromise<IpsosRegistrationResponse> {
            return localVarFp.register(ipsosRegistrationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveIpsosActivityQuestionnaireRequest} [saveIpsosActivityQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest?: SaveIpsosActivityQuestionnaireRequest, options?: any): AxiosPromise<SaveIpsosActivityQuestionnaireResponse> {
            return localVarFp.saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveIpsosIntroductionQuestionnaireRequest} [saveIpsosIntroductionQuestionnaireRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest?: SaveIpsosIntroductionQuestionnaireRequest, options?: any): AxiosPromise<SaveIpsosIntroductionQuestionnaireResponse> {
            return localVarFp.saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<any>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadImage(images?: Array<any>, options?: any): AxiosPromise<UploadImageResponse> {
            return localVarFp.uploadImage(images, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IpsosApi - object-oriented interface
 * @export
 * @class IpsosApi
 * @extends {BaseAPI}
 */
export class IpsosApi extends BaseAPI {
    /**
     * 
     * @param {string} confirmationId 
     * @param {string} [confirmationToken] 
     * @param {IpsosConfirmActivityRequest} [ipsosConfirmActivityRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public confirmActivity(confirmationId: string, confirmationToken?: string, ipsosConfirmActivityRequest?: IpsosConfirmActivityRequest, options?: any) {
        return IpsosApiFp(this.configuration).confirmActivity(confirmationId, confirmationToken, ipsosConfirmActivityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public countActivityQuestionnaires(options?: any) {
        return IpsosApiFp(this.configuration).countActivityQuestionnaires(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public countIntroductionQuestionnaires(options?: any) {
        return IpsosApiFp(this.configuration).countIntroductionQuestionnaires(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public countUsers(options?: any) {
        return IpsosApiFp(this.configuration).countUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public getActivityImage(id: number, options?: any) {
        return IpsosApiFp(this.configuration).getActivityImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public getActivityImages(id: number, options?: any) {
        return IpsosApiFp(this.configuration).getActivityImages(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public getActivityQuestionnaires(options?: any) {
        return IpsosApiFp(this.configuration).getActivityQuestionnaires(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public getIntroductionQuestionnaires(options?: any) {
        return IpsosApiFp(this.configuration).getIntroductionQuestionnaires(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public getUsers(options?: any) {
        return IpsosApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public hasAnsweredIntroductionQuestionnaire(options?: any) {
        return IpsosApiFp(this.configuration).hasAnsweredIntroductionQuestionnaire(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public isAdmin(options?: any) {
        return IpsosApiFp(this.configuration).isAdmin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IpsosRegistrationRequest} [ipsosRegistrationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public register(ipsosRegistrationRequest?: IpsosRegistrationRequest, options?: any) {
        return IpsosApiFp(this.configuration).register(ipsosRegistrationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveIpsosActivityQuestionnaireRequest} [saveIpsosActivityQuestionnaireRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest?: SaveIpsosActivityQuestionnaireRequest, options?: any) {
        return IpsosApiFp(this.configuration).saveActivityQuestionnaire(saveIpsosActivityQuestionnaireRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveIpsosIntroductionQuestionnaireRequest} [saveIpsosIntroductionQuestionnaireRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest?: SaveIpsosIntroductionQuestionnaireRequest, options?: any) {
        return IpsosApiFp(this.configuration).saveIntroductionQuestionnaire(saveIpsosIntroductionQuestionnaireRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<any>} [images] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IpsosApi
     */
    public uploadImage(images?: Array<any>, options?: any) {
        return IpsosApiFp(this.configuration).uploadImage(images, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} inboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnreadMessages: async (inboxId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inboxId' is not null or undefined
            assertParamExists('countUnreadMessages', 'inboxId', inboxId)
            const localVarPath = `/v1/messages/inbox/{inboxId}/unread/count`
                .replace(`{${"inboxId"}}`, encodeURIComponent(String(inboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} threadId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageThread: async (inboxId: number, threadId: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inboxId' is not null or undefined
            assertParamExists('getMessageThread', 'inboxId', inboxId)
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getMessageThread', 'threadId', threadId)
            const localVarPath = `/v1/messages/inbox/{inboxId}/threads/{threadId}`
                .replace(`{${"inboxId"}}`, encodeURIComponent(String(inboxId)))
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageThreads: async (inboxId: number, page?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inboxId' is not null or undefined
            assertParamExists('getMessageThreads', 'inboxId', inboxId)
            const localVarPath = `/v1/messages/inbox/{inboxId}`
                .replace(`{${"inboxId"}}`, encodeURIComponent(String(inboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead: async (deliveryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('markMessageAsRead', 'deliveryId', deliveryId)
            const localVarPath = `/v1/messages/{deliveryId}/read`
                .replace(`{${"deliveryId"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread: async (deliveryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deliveryId' is not null or undefined
            assertParamExists('markMessageAsUnread', 'deliveryId', deliveryId)
            const localVarPath = `/v1/messages/{deliveryId}/read`
                .replace(`{${"deliveryId"}}`, encodeURIComponent(String(deliveryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendMessageRequest} [sendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (sendMessageRequest?: SendMessageRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} inboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUnreadMessages(inboxId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countUnreadMessages(inboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} threadId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageThread(inboxId: number, threadId: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageThread(inboxId, threadId, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageThreads(inboxId: number, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThreadPreviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageThreads(inboxId, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsRead(deliveryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsRead(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsUnread(deliveryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsUnread(deliveryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SendMessageRequest} [sendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(sendMessageRequest?: SendMessageRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(sendMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} inboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnreadMessages(inboxId: number, options?: any): AxiosPromise<MessageCountResponse> {
            return localVarFp.countUnreadMessages(inboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} threadId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageThread(inboxId: number, threadId: number, page?: number, options?: any): AxiosPromise<ThreadResponse> {
            return localVarFp.getMessageThread(inboxId, threadId, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} inboxId 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageThreads(inboxId: number, page?: number, options?: any): AxiosPromise<Array<ThreadPreviewResponse>> {
            return localVarFp.getMessageThreads(inboxId, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead(deliveryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsRead(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} deliveryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsUnread(deliveryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsUnread(deliveryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendMessageRequest} [sendMessageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(sendMessageRequest?: SendMessageRequest, options?: any): AxiosPromise<SendMessageResponse> {
            return localVarFp.sendMessage(sendMessageRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @param {number} inboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public countUnreadMessages(inboxId: number, options?: any) {
        return MessageApiFp(this.configuration).countUnreadMessages(inboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} inboxId 
     * @param {number} threadId 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessageThread(inboxId: number, threadId: number, page?: number, options?: any) {
        return MessageApiFp(this.configuration).getMessageThread(inboxId, threadId, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} inboxId 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessageThreads(inboxId: number, page?: number, options?: any) {
        return MessageApiFp(this.configuration).getMessageThreads(inboxId, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public markMessageAsRead(deliveryId: number, options?: any) {
        return MessageApiFp(this.configuration).markMessageAsRead(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} deliveryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public markMessageAsUnread(deliveryId: number, options?: any) {
        return MessageApiFp(this.configuration).markMessageAsUnread(deliveryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendMessageRequest} [sendMessageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public sendMessage(sendMessageRequest?: SendMessageRequest, options?: any) {
        return MessageApiFp(this.configuration).sendMessage(sendMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotification: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveNotification', 'id', id)
            const localVarPath = `/v1/notifications/{id}/archived`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [markAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification: async (id: number, markAsRead?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotification', 'id', id)
            const localVarPath = `/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)

            if (markAsRead !== undefined) {
                localVarQueryParameter['markAsRead'] = markAsRead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {boolean} [includeArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (page?: number, includeArchived?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (includeArchived !== undefined) {
                localVarQueryParameter['includeArchived'] = includeArchived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/unread/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markNotificationAsRead', 'id', id)
            const localVarPath = `/v1/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsUnread: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markNotificationAsUnread', 'id', id)
            const localVarPath = `/v1/notifications/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNotification(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNotification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [markAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotification(id: number, markAsRead?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotification(id, markAsRead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {boolean} [includeArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(page?: number, includeArchived?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(page, includeArchived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationAsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsUnread(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationAsUnread(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotification(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.archiveNotification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [markAsRead] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(id: number, markAsRead?: boolean, options?: any): AxiosPromise<NotificationResponse> {
            return localVarFp.getNotification(id, markAsRead, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {boolean} [includeArchived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(page?: number, includeArchived?: boolean, options?: any): AxiosPromise<Array<NotificationResponse>> {
            return localVarFp.getNotifications(page, includeArchived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsCount(options?: any): AxiosPromise<NotificationResponse> {
            return localVarFp.getUnreadNotificationsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationAsRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsUnread(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationAsUnread(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public archiveNotification(id: number, options?: any) {
        return NotificationApiFp(this.configuration).archiveNotification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [markAsRead] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotification(id: number, markAsRead?: boolean, options?: any) {
        return NotificationApiFp(this.configuration).getNotification(id, markAsRead, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {boolean} [includeArchived] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotifications(page?: number, includeArchived?: boolean, options?: any) {
        return NotificationApiFp(this.configuration).getNotifications(page, includeArchived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getUnreadNotificationsCount(options?: any) {
        return NotificationApiFp(this.configuration).getUnreadNotificationsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public markNotificationAsRead(id: number, options?: any) {
        return NotificationApiFp(this.configuration).markNotificationAsRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public markNotificationAsUnread(id: number, options?: any) {
        return NotificationApiFp(this.configuration).markNotificationAsUnread(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrganizationRequest} [createOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization: async (createOrganizationRequest?: CreateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [keywords] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrganizations: async (keywords?: string, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keywords !== undefined) {
                localVarQueryParameter['keywords'] = keywords;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganization', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations: async (page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/organizations/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization: async (id: number, updateOrganizationRequest?: UpdateOrganizationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganization', 'id', id)
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationNameRequest} [updateOrganizationNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationName: async (id: number, updateOrganizationNameRequest?: UpdateOrganizationNameRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOrganizationName', 'id', id)
            const localVarPath = `/v1/organizations/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrganizationRequest} [createOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganization(createOrganizationRequest?: CreateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganization(createOrganizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [keywords] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOrganizations(keywords?: string, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOrganizations(keywords, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyOrganizations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyOrganizations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganization(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganization(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizations(page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizations(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganization(id: number, updateOrganizationRequest?: UpdateOrganizationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganization(id, updateOrganizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationNameRequest} [updateOrganizationNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationName(id: number, updateOrganizationNameRequest?: UpdateOrganizationNameRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationName(id, updateOrganizationNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOrganizationRequest} [createOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganization(createOrganizationRequest?: CreateOrganizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createOrganization(createOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [keywords] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOrganizations(keywords?: string, page?: number, options?: any): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.findOrganizations(keywords, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizations(options?: any): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.getMyOrganizations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganization(id: number, options?: any): AxiosPromise<OrganizationResponse> {
            return localVarFp.getOrganization(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizations(page?: number, options?: any): AxiosPromise<Array<OrganizationResponse>> {
            return localVarFp.getOrganizations(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsCount(options?: any): AxiosPromise<OrganizationCountResponse> {
            return localVarFp.getOrganizationsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganization(id: number, updateOrganizationRequest?: UpdateOrganizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrganization(id, updateOrganizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateOrganizationNameRequest} [updateOrganizationNameRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationName(id: number, updateOrganizationNameRequest?: UpdateOrganizationNameRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrganizationName(id, updateOrganizationNameRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @param {CreateOrganizationRequest} [createOrganizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public createOrganization(createOrganizationRequest?: CreateOrganizationRequest, options?: any) {
        return OrganizationApiFp(this.configuration).createOrganization(createOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [keywords] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public findOrganizations(keywords?: string, page?: number, options?: any) {
        return OrganizationApiFp(this.configuration).findOrganizations(keywords, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getMyOrganizations(options?: any) {
        return OrganizationApiFp(this.configuration).getMyOrganizations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganization(id: number, options?: any) {
        return OrganizationApiFp(this.configuration).getOrganization(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizations(page?: number, options?: any) {
        return OrganizationApiFp(this.configuration).getOrganizations(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationsCount(options?: any) {
        return OrganizationApiFp(this.configuration).getOrganizationsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateOrganizationRequest} [updateOrganizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrganization(id: number, updateOrganizationRequest?: UpdateOrganizationRequest, options?: any) {
        return OrganizationApiFp(this.configuration).updateOrganization(id, updateOrganizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateOrganizationNameRequest} [updateOrganizationNameRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public updateOrganizationName(id: number, updateOrganizationNameRequest?: UpdateOrganizationNameRequest, options?: any) {
        return OrganizationApiFp(this.configuration).updateOrganizationName(id, updateOrganizationNameRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationAddressApi - axios parameter creator
 * @export
 */
export const OrganizationAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAddress: async (organizationId: number, upsertAddressRequest?: UpsertAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('createOrganizationAddress', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses/addresses`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAddress: async (organizationId: number, addressId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationAddress', 'organizationId', organizationId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('getOrganizationAddress', 'addressId', addressId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses/{addressId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAddresses: async (organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationAddresses', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDefaultAddress: async (organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationDefaultAddress', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses/default`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationDefaultAddress: async (organizationId: number, addressId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('setOrganizationDefaultAddress', 'organizationId', organizationId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('setOrganizationDefaultAddress', 'addressId', addressId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses/{addressId}/default`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationAddress: async (organizationId: number, addressId: number, upsertAddressRequest?: UpsertAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationAddress', 'organizationId', organizationId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('updateOrganizationAddress', 'addressId', addressId)
            const localVarPath = `/v1/organizations/{organizationId}/addresses/{addressId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationAddressApi - functional programming interface
 * @export
 */
export const OrganizationAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationAddress(organizationId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationAddress(organizationId, upsertAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAddress(organizationId: number, addressId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationAddress(organizationId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationAddresses(organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationAddresses(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDefaultAddress(organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationDefaultAddress(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrganizationDefaultAddress(organizationId: number, addressId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrganizationDefaultAddress(organizationId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationAddress(organizationId: number, addressId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationAddress(organizationId, addressId, upsertAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationAddressApi - factory interface
 * @export
 */
export const OrganizationAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationAddressApiFp(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationAddress(organizationId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): AxiosPromise<CreateAddressResponse> {
            return localVarFp.createOrganizationAddress(organizationId, upsertAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAddress(organizationId: number, addressId: number, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.getOrganizationAddress(organizationId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationAddresses(organizationId: number, options?: any): AxiosPromise<Array<AddressResponse>> {
            return localVarFp.getOrganizationAddresses(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDefaultAddress(organizationId: number, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.getOrganizationDefaultAddress(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationDefaultAddress(organizationId: number, addressId: number, options?: any): AxiosPromise<void> {
            return localVarFp.setOrganizationDefaultAddress(organizationId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} addressId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationAddress(organizationId: number, addressId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateOrganizationAddress(organizationId, addressId, upsertAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationAddressApi - object-oriented interface
 * @export
 * @class OrganizationAddressApi
 * @extends {BaseAPI}
 */
export class OrganizationAddressApi extends BaseAPI {
    /**
     * 
     * @param {number} organizationId 
     * @param {UpsertAddressRequest} [upsertAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public createOrganizationAddress(organizationId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any) {
        return OrganizationAddressApiFp(this.configuration).createOrganizationAddress(organizationId, upsertAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public getOrganizationAddress(organizationId: number, addressId: number, options?: any) {
        return OrganizationAddressApiFp(this.configuration).getOrganizationAddress(organizationId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public getOrganizationAddresses(organizationId: number, options?: any) {
        return OrganizationAddressApiFp(this.configuration).getOrganizationAddresses(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public getOrganizationDefaultAddress(organizationId: number, options?: any) {
        return OrganizationAddressApiFp(this.configuration).getOrganizationDefaultAddress(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public setOrganizationDefaultAddress(organizationId: number, addressId: number, options?: any) {
        return OrganizationAddressApiFp(this.configuration).setOrganizationDefaultAddress(organizationId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} addressId 
     * @param {UpsertAddressRequest} [upsertAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationAddressApi
     */
    public updateOrganizationAddress(organizationId: number, addressId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any) {
        return OrganizationAddressApiFp(this.configuration).updateOrganizationAddress(organizationId, addressId, upsertAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationMemberApi - axios parameter creator
 * @export
 */
export const OrganizationMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {AddOrganizationMemberRequest} [addOrganizationMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationMember: async (organizationId: number, addOrganizationMemberRequest?: AddOrganizationMemberRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('addOrganizationMember', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrganizationMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationMember: async (organizationId: number, personId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationMember', 'organizationId', organizationId)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getOrganizationMember', 'personId', personId)
            const localVarPath = `/v1/organizations/{organizationId}/members/{personId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationMembers: async (organizationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationMembers', 'organizationId', organizationId)
            const localVarPath = `/v1/organizations/{organizationId}/members`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrganizationMember: async (organizationId: number, personId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('removeOrganizationMember', 'organizationId', organizationId)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('removeOrganizationMember', 'personId', personId)
            const localVarPath = `/v1/organizations/{organizationId}/members/{personId}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {SetOrganizationMemberRoleRequest} [setOrganizationMemberRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationMemberRole: async (organizationId: number, personId: number, setOrganizationMemberRoleRequest?: SetOrganizationMemberRoleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('setOrganizationMemberRole', 'organizationId', organizationId)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('setOrganizationMemberRole', 'personId', personId)
            const localVarPath = `/v1/organizations/{organizationId}/members/{personId}/role`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setOrganizationMemberRoleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationMemberApi - functional programming interface
 * @export
 */
export const OrganizationMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {AddOrganizationMemberRequest} [addOrganizationMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrganizationMember(organizationId: number, addOrganizationMemberRequest?: AddOrganizationMemberRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationMember(organizationId, addOrganizationMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationMember(organizationId: number, personId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationMemberResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationMember(organizationId, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationMembers(organizationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationMemberResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationMembers(organizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOrganizationMember(organizationId: number, personId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrganizationMember(organizationId, personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {SetOrganizationMemberRoleRequest} [setOrganizationMemberRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrganizationMemberRole(organizationId: number, personId: number, setOrganizationMemberRoleRequest?: SetOrganizationMemberRoleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrganizationMemberRole(organizationId, personId, setOrganizationMemberRoleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationMemberApi - factory interface
 * @export
 */
export const OrganizationMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationMemberApiFp(configuration)
    return {
        /**
         * 
         * @param {number} organizationId 
         * @param {AddOrganizationMemberRequest} [addOrganizationMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrganizationMember(organizationId: number, addOrganizationMemberRequest?: AddOrganizationMemberRequest, options?: any): AxiosPromise<void> {
            return localVarFp.addOrganizationMember(organizationId, addOrganizationMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationMember(organizationId: number, personId: number, options?: any): AxiosPromise<OrganizationMemberResponse> {
            return localVarFp.getOrganizationMember(organizationId, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationMembers(organizationId: number, options?: any): AxiosPromise<Array<OrganizationMemberResponse>> {
            return localVarFp.getOrganizationMembers(organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOrganizationMember(organizationId: number, personId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeOrganizationMember(organizationId, personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} organizationId 
         * @param {number} personId 
         * @param {SetOrganizationMemberRoleRequest} [setOrganizationMemberRoleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrganizationMemberRole(organizationId: number, personId: number, setOrganizationMemberRoleRequest?: SetOrganizationMemberRoleRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setOrganizationMemberRole(organizationId, personId, setOrganizationMemberRoleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationMemberApi - object-oriented interface
 * @export
 * @class OrganizationMemberApi
 * @extends {BaseAPI}
 */
export class OrganizationMemberApi extends BaseAPI {
    /**
     * 
     * @param {number} organizationId 
     * @param {AddOrganizationMemberRequest} [addOrganizationMemberRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationMemberApi
     */
    public addOrganizationMember(organizationId: number, addOrganizationMemberRequest?: AddOrganizationMemberRequest, options?: any) {
        return OrganizationMemberApiFp(this.configuration).addOrganizationMember(organizationId, addOrganizationMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationMemberApi
     */
    public getOrganizationMember(organizationId: number, personId: number, options?: any) {
        return OrganizationMemberApiFp(this.configuration).getOrganizationMember(organizationId, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationMemberApi
     */
    public getOrganizationMembers(organizationId: number, options?: any) {
        return OrganizationMemberApiFp(this.configuration).getOrganizationMembers(organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationMemberApi
     */
    public removeOrganizationMember(organizationId: number, personId: number, options?: any) {
        return OrganizationMemberApiFp(this.configuration).removeOrganizationMember(organizationId, personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} organizationId 
     * @param {number} personId 
     * @param {SetOrganizationMemberRoleRequest} [setOrganizationMemberRoleRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationMemberApi
     */
    public setOrganizationMemberRole(organizationId: number, personId: number, setOrganizationMemberRoleRequest?: SetOrganizationMemberRoleRequest, options?: any) {
        return OrganizationMemberApiFp(this.configuration).setOrganizationMemberRole(organizationId, personId, setOrganizationMemberRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonActivities: async (personId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getPersonActivities', 'personId', personId)
            const localVarPath = `/v1/persons/{personId}/activities`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonActivities(personId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonActivities(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonApiFp(configuration)
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonActivities(personId: number, options?: any): AxiosPromise<Array<ActivityResponse>> {
            return localVarFp.getPersonActivities(personId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
    /**
     * 
     * @param {number} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonApi
     */
    public getPersonActivities(personId: number, options?: any) {
        return PersonApiFp(this.configuration).getPersonActivities(personId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonAddressApi - axios parameter creator
 * @export
 */
export const PersonAddressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAddress: async (personId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getPersonAddress', 'personId', personId)
            const localVarPath = `/v1/persons/{personId}/address`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} personId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonAddress: async (personId: number, upsertAddressRequest?: UpsertAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updatePersonAddress', 'personId', personId)
            const localVarPath = `/v1/persons/{personId}/address`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["maschvilku"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonAddressApi - functional programming interface
 * @export
 */
export const PersonAddressApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonAddressApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonAddress(personId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonAddress(personId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} personId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonAddress(personId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonAddress(personId, upsertAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonAddressApi - factory interface
 * @export
 */
export const PersonAddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonAddressApiFp(configuration)
    return {
        /**
         * 
         * @param {number} personId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonAddress(personId: number, options?: any): AxiosPromise<AddressResponse> {
            return localVarFp.getPersonAddress(personId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} personId 
         * @param {UpsertAddressRequest} [upsertAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonAddress(personId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonAddress(personId, upsertAddressRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonAddressApi - object-oriented interface
 * @export
 * @class PersonAddressApi
 * @extends {BaseAPI}
 */
export class PersonAddressApi extends BaseAPI {
    /**
     * 
     * @param {number} personId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public getPersonAddress(personId: number, options?: any) {
        return PersonAddressApiFp(this.configuration).getPersonAddress(personId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} personId 
     * @param {UpsertAddressRequest} [upsertAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonAddressApi
     */
    public updatePersonAddress(personId: number, upsertAddressRequest?: UpsertAddressRequest, options?: any) {
        return PersonAddressApiFp(this.configuration).updatePersonAddress(personId, upsertAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersion: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/system/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDateTime: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/system/datetime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiVersion(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentDateTime(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentDateTime(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiVersion(options?: any): AxiosPromise<VersionResponse> {
            return localVarFp.getApiVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentDateTime(options?: any): AxiosPromise<DateTimeResponse> {
            return localVarFp.getCurrentDateTime(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getApiVersion(options?: any) {
        return SystemApiFp(this.configuration).getApiVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCurrentDateTime(options?: any) {
        return SystemApiFp(this.configuration).getCurrentDateTime(options).then((request) => request(this.axios, this.basePath));
    }
}


