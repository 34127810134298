import React from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../commons/context/AppContextProvider";

interface IState {
	isReady: boolean;
}

export default class AuthCallback extends React.Component<{}, IState> {
	static contextType = AppContext;

	constructor(props: {}) {
		super(props);

		this.state = {
			isReady: false
		};
	}

	async componentDidMount() {
		const token = await this.context.authClient.handleRedirectPromise();
		if (token !== null) {
			const accounts = this.context.authClient.getAllAccounts();
			if (accounts.length > 0) {
				this.context.authClient.setActiveAccount(accounts[0]);
			}
		}

		this.setState({ isReady: true });
	}

	render() {
		return (
			<React.Fragment>
				<div>Probíhá přihlašování...</div>
				{this.state.isReady && <Redirect to="/" />}
			</React.Fragment>
		);
	}
}
